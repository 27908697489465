import styles from './Menu.css'

/** A játéktér felső sávbja. */
export default function Menu (): HTMLElement {
  return (
    <div className={ styles.menu }>
      <div className="container-fluid">
        <div className="row">
          <div className={ [ 'col-sm-6', styles.logoContainer ] }>
            <div className={ styles.logo } />
          </div>
        </div>
      </div>
    </div>
  )
}
