import type { IGameProps } from '../definitions'

export enum EStatHandlerEvent {
  Init              = 'Game:StatHandler:Init',
  Kill              = 'Game:StatHandler:Kill',
  Clear             = 'Game:StatHandler:Clear',
  Restart           = 'Game:StatHandler:Restart',
  SaveDate          = 'Game:StatHandler:SaveDate',
  EndRound          = 'Game:StatHandler:EndRound',
  NextRound         = 'Game:StatHandler:NextRound',
  SaveDuration      = 'Game:StatHandler:SaveDuration',

  AddSolutions      = 'Game:StatHandler:AddSolutions',
  CorrectSolution   = 'Game:StatHandler:CorrectSolution',
  IncorrectSolution = 'Game:StatHandler:IncorrectSolution',

  GetData           = 'Game:StatHandler:GetData',
  SendData          = 'Game:StatHandler:SendData',

  GetActRound       = 'Game:StatHandler:GetActRound',
  SendActRound      = 'Game:StatHandler:SendActRound',

  GetIsLastRound    = 'Game:StatHandler:GetIsLastRound',
  SendIsLastRound   = 'Game:StatHandler:SendIsLastRound',

  SendGameProps     = 'Game:StatHandler:SendGameProps'
}

// STAT
export const SOLUTIONS = [ 'correct', 'incorrect' ] as const

export type TSolutionType = typeof SOLUTIONS[number]

export type TSolutionTypes = readonly TSolutionType[]

export type TCustomStat = string | number | TAnyObject

export interface ISolution<T extends TCustomStat = TCustomStat> {
  readonly type: TSolutionType
  readonly duration: number
  custom?: T
}

export type TSolutions<T extends TCustomStat = TCustomStat> = ISolution<T>[]

export interface IRawRoundStat<T extends TCustomStat = TCustomStat> {
  duration: number
  solutions: TSolutions<T>
}

export type TRawRoundStat<T extends TCustomStat = TCustomStat> = IRawRoundStat<T>[]

export type TRawStat<T extends TCustomStat = TCustomStat> = TRawRoundStat<T>

export interface IRoundStat<T extends TCustomStat = TCustomStat> {
  readonly duration: number
  readonly correctSolutions: number
  readonly incorrectSolutions: number
  readonly solutions: TSolutions<T>
}

export type TRoundStats<T extends TCustomStat = TCustomStat> = IRoundStat<T>[]

interface ISolutionOverallData {
  count: number
  duration: number
}

export type TOverallSolution = Record<TSolutionType, ISolutionOverallData>

export interface IOverall {
  readonly duration: number
  readonly solutions: TOverallSolution
}

export type TOverallSolutionsDuration = Record<TSolutionType, number>

export interface IStat<T extends TCustomStat = TCustomStat> {
  readonly overall: IOverall
  readonly roundStats: TRoundStats<T>
}

export type TStatType = keyof IStat

export const DEFAULT_STAT_DATA: IStat = {
  overall: {
    duration: 0,
    solutions: {
      correct: { count: 0, duration: 0 },
      incorrect: { count: 0, duration: 0 }
    }
  },
  roundStats: []
}

export interface IDuration {
  minutes: number
  seconds: number
}

export interface IGetDataProps<T extends TCustomStat = TCustomStat> {
  readonly data: TRawStat<T>
  readonly gameProps: IGameProps
  readonly isExit?: boolean
}

export type { IGameProps }

export type { IEvent } from '../definitions'
