import type { TPlaygroundApps } from '../../definitions'

import Card from '../Card/Card'

import styles from './Apps.scss'

interface IProps {
  readonly apps: TPlaygroundApps
}

/**
 * Az appok megjelenítéséért és a kártyák rendezéséért felelős komponens factory-ja.
 * @param props - JSX props {@link IProps}.
 */
export default function Apps (props: IProps): HTMLElement {
  const res = (
    // Teljes szélességben mutatjuk az appokat.
    // Sima "container"-re rakd át, ha nem így szeretnéd!
    <div className="container-fluid">
      <div className="row">
        {
          props.apps.map((app) => (
            <div
              className={ [
                'col-xs-12', // 1
                'col-sm-6',  // 2
                'col-md-4',  // 3
                'col-lg-3',  // 4
                'col-xl-2',  // 6
                styles.cell
              ] }
            >
              <Card isDemo={ false } serial={ app.serial } title={ app.name } />
            </div>
          ))
        }
      </div>
    </div>
  )

  return res
}
