const HACKNAMES = {
  kulonbsegkereso:     'Különbség-<br>kereső',
  kepessegfejlesztes1: 'Képesség-<br>fejlesztés I.',
  kornyezetvedelem1:   'Környezet-<br>védelem'
} as const

/**
 * Hack, hogy jó legyen az elválasztás megjelenítése.
 * @param title - A játék eredeti neve.
 */
export default function getName (title: string): string {
  if (title === 'Különbségkereső') {
    return HACKNAMES.kulonbsegkereso
  }

  if (title === 'Képességfejlesztés I.') {
    return HACKNAMES.kepessegfejlesztes1
  }

  if (title === 'Környezetvédelem') {
    return HACKNAMES.kornyezetvedelem1
  }

  return title
}
