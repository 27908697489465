/* eslint-disable unicorn/consistent-destructuring */

import { baseGlobals } from '@root/typescript/baseGlobals'

import WIP_IMAGE from './images/fejlesztes-alatt-placeholder-fejleszto-oktatasi-jatek.jpg'

type TSize = 'icon' | 'small' | 'medium' | 'large'

type TType = 'normal' | 'favicon'

type TSizes = Record<TSize, number>

const SIZE: TSizes = {
  icon:   60,
  small:  120,
  medium: 160,
  large:  350
}

interface IProps {
  readonly serial:  string
  readonly size:    TSize
  readonly type?:   TType
  readonly src?:    string
}

/**
 * Egy Ügyesedni app képét megjelenítő komponens.
 * @param props - JSX props {@link IProps}.
 */
export default function AppImage (props: IProps): HTMLImageElement {
  const { size } = props
  const imgType = props.type ?? 'normal'

  const postfix = imgType === 'favicon'
    ? 'favicon'
    : (
      size === 'small' || size === 'medium' || size === 'icon'
        ? 'small'
        : 'large'
    )

  const fileName = `${ props.serial }-${ postfix }.png`

  const src = props.src
    ? `${ props.src }/${ fileName }`
    // eslint-disable-next-line custom/no-nested-ternary -- Legacy, jó ez így.
    : `${ baseGlobals.isDev ? 'http://localhost:8080/' : '' }images/${ fileName }`

  const s = SIZE[size]

  const img: HTMLImageElement = (
    <img
      width={ s }
      height={ s }
      alt={ src }
      draggable={ false }
      src={ src }
    />
  )

  img.addEventListener('error', () => {
    img.src = WIP_IMAGE
  })

  return img
}
