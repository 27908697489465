import styles from './Column.scss'

interface IColumnProps {
  readonly title: string
  readonly children?: HTMLElement[]
}

/**
 * A Footer komponensben belül egy oszlopnyi adat.
 * @param props - JSX props {@link IColumnProps}.
 */
export default function Column (props: IColumnProps): HTMLElement {
  return (
    <div className={ [ 'col-sm-6', 'col-md-4', 'col-lg-3', styles.column ] }>
      <h3 className={ styles.title }>{ props.title }</h3>
      <div className={ styles.wave } />
      { props.children ?? null }
    </div>
  )
}
