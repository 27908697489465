import type { Howl } from 'howler'

import type { TAudioFormat } from '@common/BaseSoundSystem/definitions'

export const PRELOADER_FADEOUT_DURATION = 1000

export enum EPreloaderEvent {
  AssetsLoaded = 'Preloader:Assets:Loaded',
  FontsLoaded  = 'Preloader:Fonts:Loaded',
  Clickable    = 'Preloader:Clickable',
  Loaded       = 'Preloader:Loaded',
  Removed      = 'Preloader:Removed',
  Start        = 'Preloader:Start'
}

export type TPreloadedAudio = Record<string, Howl>

export interface IPreloadedAudio {
  readonly preloadedAudios: TPreloadedAudio
}

export type TPreloadedAudios = {
  [key in TAudioFormat]: TPreloadedAudio
}

export { MODULAR_ATTR } from '../definitions'

export type { TSoundTestResult } from '@common/BaseSoundSystem/definitions'

export type { TPossiblePreloadedData, TPossiblePreloadedDatas } from '@common/BasePreloader/definitions'

export { EAppPostMessageCommand } from '@ugyesedni/definitions'
