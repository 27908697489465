import type { TSound } from '@apps/ss'

import { EIcon } from '../Icon/definitions'

interface ISoundButtonIcons {
  readonly enabled: EIcon
  readonly disabled: EIcon
}

type TSoundButtonIcon = {
  readonly [key in TSound]: ISoundButtonIcons
}

export const SOUND_BUTTON_ICONS: TSoundButtonIcon = {
  sfx:      { enabled: EIcon.SoundOn,     disabled: EIcon.SoundOff },
  music:    { enabled: EIcon.MusicOn,     disabled: EIcon.MusicOff },
  narrator: { enabled: EIcon.NarrationOn, disabled: EIcon.NarrationOff }
}

export interface ISoundStateChange {
  readonly type: TSound
}

export enum ESoundButtonEvent {
  StateChanged = 'SoundButton:StateChanged'
}

export { EIcon }
export type { TSound }

export { ESize, EColor } from '../Button/definitions'
