import { baseGlobals } from '@root/typescript/baseGlobals'

import ajax from '@common/ajax/v1/ajax'

import trans from '@common/i18n/v1/trans'

import init from '../../init'

import styles from './Status.scss'

/**
 * Az aktuális státusza a felhasználónak arról, hogy be van-e jelentkezve vagy nincs.
 * Bejelentkezett állapotban kijelentkező gombként funkcionál.
 */
export default function Status (): HTMLElement {
  const url = `${ baseGlobals.isDev ? 'http://localhost:3100' : '' }/api/logout`

  /** Kijelentkezés. */
  function onClickSignOut (): void {
    ajax(url, { withCredentials: true })

    // Majd kvázi újrainicializáljuk.
    init()
  }

  const status = (
    <div className={ styles.status }>
      <span className={ styles.signedOut }>{ trans('Status.signedOut') }</span>
      <span className={ styles.signedIn } onClick={ onClickSignOut }>{ trans('Status.signedIn') }</span>
    </div>
  )

  return status
}
