import { baseGlobals } from '@root/typescript/baseGlobals'

import { is } from '@common/is/is'

import scroll from '@common/scroll/scroll'

import DailyCode from './components/DailyCode/DailyCode'

import type { TPlaygroundData } from './definitions'

import renderProdApps from './renderProdApps'
import getDailyCodeElement from './getDailyCodeElement'

/**
 * Bejelentkezéskor hívódó callback.
 * @param data         - Az appok, amiket mutatni kell bejelentkezéskor.
 * @param dailyCode    - A felhasználó napi kódja vagy undefined.
 * @param attachedApps - Az elérhető appok ID listája.
 * @param theme        - Az ügyfélhez kapcsolt grafikai téma.
 */
export default function signIn ( // eslint-disable-line @typescript-eslint/max-params -- Legacy.
  data: TPlaygroundData,
  attachedApps: number[],
  dailyCode?: string,
  theme = 'default'
): void {
  document.body.setAttribute('data-signed', 'in')
  document.body.setAttribute('data-theme', theme)

  const dc = is.string(dailyCode) && !is.empty(dailyCode)
    ? dailyCode
    // eslint-disable-next-line custom/no-nested-ternary -- Legacy, jó ez így.
    : baseGlobals.isDev
      ? 'dev123'
      : undefined

  if (dc) {
    const elem = getDailyCodeElement()

    if (is.instanceOf(elem, HTMLElement)) {
      elem.appendChild(<DailyCode code={ dc } />)
    }
  }

  renderProdApps(data, attachedApps, true)

  scroll.toTop()
}
