import './Owl.css'

const OWLS = [
  'welcome',     // Üdövzlő
  'school-prep', // Iskolai előkészítő
  'math',         // Matek
  'grammar',      // Nyelvtan
  'lower-grade',  // Alsó tagozatos
  'algorithm',    // Problémamegoldó és algoritmikus
  'sen',          // SNI
  'tobii',        // Szemvezérlős
  'all'           // Összes
] as const

type TOwl = typeof OWLS[number]

interface IProps {
  readonly type: TOwl
}

type TImages = {
  readonly [key in TOwl]: string
}

const IMAGES: TImages = {
  welcome: '01_udvozlo',
  'school-prep': '02_iskolai-elokeszito',
  math: '03_matek',
  grammar: '04_magyar-nyelv',
  'lower-grade': '05_also-tagozatos-altalanos-kf',
  algorithm: '06_problemamegoldo-es-algoritmikus-g',
  sen: '07_SNI',
  tobii: '08_tobii_szemvezerlos',
  all: '09_osszes-csomag_feherkonturnelkul'
}

/**
 * Az Ügyesedninek a Brigi-féle bagoly kabalának a factory-ja.
 * @param props - JSX props {@link IProps}.
 */
export default function Owl (props: IProps): HTMLElement {
  const owl: HTMLElement = <div className="sen-owl" />

  import(
    /* webpackChunkName: "owl/[index]" */
    `./images/új-honlap_bagoly-illusztraciok_${ IMAGES[props.type] }.png`
  )
    .then(({ default: img }) => {
      owl.style.backgroundImage = `url(${ img })`
    })

  return owl
}
