import { baseGlobals } from '@root/typescript/baseGlobals'

import ClientCookie from '@common/Cookie/ClientCookie'

import { SOURCE_COOKIE_NAME, SESSION_COOKIE_NAME } from './definitions'

import type { TSourceType } from './definitions'

const domain = baseGlobals.isDev ? 'localhost' : '.ugyesedni.hu'

export default class CookieHandler {
  /** Törli a mentett cookie-t. */
  public static deleteCookie (): void {
    ClientCookie.delete(SESSION_COOKIE_NAME, '/', domain)
  }

  /**
   * Létrehoz és ment egy cookie-t.
   * @param value - A süti értéke.
   */
  public static setCookie (value: string): void {
    CookieHandler.deleteCookie()

    ClientCookie.set(
      ClientCookie.make({
        name: SESSION_COOKIE_NAME,
        value,
        daysToLive: 1,
        domain,
        isSecure: true,
        sameSite: 'Strict'
      })
    )
  }

  /** Beállítja a source cookiet. */
  public static setSourceCookie (): void {
    const value: TSourceType = 'playground'

    // Ha van, ha nincs, töröljük.
    ClientCookie.delete(SOURCE_COOKIE_NAME)

    ClientCookie.set(
      ClientCookie.make({
        name: SOURCE_COOKIE_NAME,
        value,
        daysToLive: 1,
        domain,
        isSecure: true,
        sameSite: 'Strict'
      })
    )
  }
}
