import type UserAgent from '@common/UserAgent/UserAgent'
import type { IGameEventHandler } from '@common/EventHandler/GameEventHandler'

import type { ILocation } from '@ugyesedni/definitions'

import type MusicPlayer from '../MusicPlayer/MusicPlayer'

import type { IAnim }               from './anim/definitions'
import type { IGame }               from './game/definitions'
import type { ITween }              from './tween/definitions'
import type { IStorage }            from './storage/definitions'
import type { IModular }            from './modular/definitions'
import type { ISysModal }           from './modal/definitions'
import type { ISysReward }          from './reward/definitions'
import type { ISysTiming }          from './timing/definitions'
import type { ISysGameOver }        from './gameOver/definitions'
import type { ISysTutorial }        from './tutorial/definitions'
import type { ISoundSystem }        from './ss/definitions'
import type { ISysUserGuide }       from './userGuide/definitions'
import type { ISysTranslator }      from './i18n/definitions'
import type { ISysNavigation }      from './navigation/definitions'
import type { ISysGameOptions }     from './gameOptions/definitions'
import type { ISysScreenTimer }     from './screenTimer/definitions'
import type { ISysStatHandler }     from './statHandler/definitions'
import type { ISysStateHandler }    from './stateHandler/definitions'
import type { IPostMessageHandler } from './postMessageHandler/definitions'

interface ISysMusicPlayer extends Omit<
// A sys-en keresztül nem engedjük elérni az alábbi a metódusokat.
MusicPlayer, 'add' | 'playSequence' | 'play' | 'stop' | 'stopAll' | 'onChangeScene'
> {}

interface ISysSoundSystem extends Omit<ISoundSystem, 'music'> {
  readonly music: ISysMusicPlayer
}

// A sys-en keresztül nem engedjük elérni az alábbi metódust.
interface ISysPostMessageHandler extends Omit<IPostMessageHandler, 'sendSoundSystemStates'> {}

export interface ISystem {
  readonly ss: ISysSoundSystem
  readonly anim: IAnim
  readonly tween: ITween
  readonly events: IGameEventHandler
  readonly storage: IStorage
  readonly i18n: ISysTranslator
  readonly game: IGame
  readonly modular: IModular
  readonly modal: ISysModal
  readonly timing: ISysTiming
  readonly reward: ISysReward
  readonly location: ILocation
  readonly gameOver: ISysGameOver
  readonly tutorial: ISysTutorial
  readonly userGuide: ISysUserGuide
  readonly navigation: ISysNavigation
  readonly gameOptions: ISysGameOptions
  readonly screenTimer: ISysScreenTimer
  readonly statHandler: ISysStatHandler
  readonly stateHandler: ISysStateHandler
  readonly userAgent: UserAgent
  readonly postMessageHandler: ISysPostMessageHandler
  readonly debugMode: {
    isActive: () => boolean
  }
}

export const LOAD_SOUNDS_EVENT = 'Sounds:Load'

export type { ILocation }

export { ERemainingTimeEvent } from '@bc/v2/RemainingTime/definitions'

export type { IReward, TRewardPart } from '@bc/v2/Reward/definitions'

export type { IModalProps } from '@bc/v2/Modal/definitions'

export { ETutorialEvent } from '@bc/v3/Tutorial/definitions'

export type { TNamespacedTrans } from '@common/i18n/definitions'

export { EFullscreenEvent } from '@common/fullscreen/definitions'

export type { TSound, ISoundSystem } from '../ss'

export type { TEvent, TSceneChangeCallback } from '../Navigation/definitions'

export type { IStat, TSolutions, TCustomStat, TSolutionTypes } from '../StatHandler/definitions'

export { EStatHandlerEvent, DEFAULT_STAT_DATA } from '../StatHandler/definitions'

export type { TDateState, IBaseStateHandler } from '../stateHandler/definitions'

export type { TLanguage } from '../translator/definitions'

export {
  EXIT_MODES, EAppPostMessageCommand, SESSION_STORAGE_LEVEL_OPTIONS_KEY
} from '@ugyesedni/definitions'

export type { TExitMode, TModuleType, IPostMessage, ISoundSystemChangePostMessage } from '@ugyesedni/definitions'

export {
  SCENES, EGameEvent, MODULAR_ATTR, MODULE_ID_ATTR, MODULE_TYPE_ATTR, EGameOptionsEvent, EUserGuideButtonEvent
} from '../definitions'

export type { IEvent, TScene, IGameProps, IFavouriteModuleProps, ISoundSystemChangeProps } from '../definitions'

export type { TCallback, TPostMessageData } from '@common/PostMessageHandler/definitions'

export type { TSoundTestResult } from '@common/BaseSoundSystem/definitions'

export type { TPreloadedAudio } from '@common/BaseWebAudioSoundSystem/definitions'

export type { IAppShellLoadedData, IPreloaderSoundResult as IPreloaderResult } from '@ugyesedni/appShell/definitions'

export { ESoundButtonEvent } from '@bc/v2/SoundButton/definitions'

export type { ISoundStateChange } from '@bc/v2/SoundButton/definitions'

export { EIsFavourite, EModulesRoute, EFavouriteModulesRoute } from '@ugyesedni/services/moduleService/definitions'

export type {
  IIsFavouriteResponse,
  IToggleFavouriteResponse,
  TAdvancedAncientLevelOptions,
  IGetModuleIdByAncientLevelOptionsRequest,
  IGetModuleIdByAncientLevelOptionsResponse
} from '@ugyesedni/services/moduleService/definitions'
