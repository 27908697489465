import { is } from '@common/is/is'

import ClientCookie from '@common/Cookie/ClientCookie'
import scroll       from '@common/scroll/scroll'

import type { TPlaygroundData } from './definitions'

import getDailyCodeElement  from './getDailyCodeElement'
import renderProdApps       from './renderProdApps'

/**
 * Bejelentkezéskor hívódó callback.
 * @param data - Az appok, amiket mutatni kell kijelentkezéskor.
 */
export default function signOut (data: TPlaygroundData): void {
  document.body.setAttribute('data-signed', 'out')
  document.body.setAttribute('data-theme', 'default')

  const dc = getDailyCodeElement()

  // Már nincs szükség a loginHash-re.
  ClientCookie.delete('ugyesedni:loginHash')

  if (is.instanceOf(dc, HTMLElement)) {
    dc.innerHTML = ''
  }

  renderProdApps(data, [], false)

  scroll.toTop()
}
