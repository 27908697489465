import starsLeft  from './images/stars_left.svg'
import starsRight from './images/stars_right.svg'

interface IProps {
  readonly side: 'left' | 'right'
}

/**
 * Csillag dekoráció factory-ja.
 * @param props - JSX props {@link IProps}.
 */
export default function Stars (props: IProps): HTMLImageElement {
  const src = props.side === 'left' ? starsLeft : starsRight

  return <img width={ 75 } height={ 35 } draggable={ false } src={ src } />
}
