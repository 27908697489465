import ClientCookie from '@common/Cookie/ClientCookie'

import styles from './CookieBlockedPanel.scss'

/** Ha le vannak blokkolva a sütik, akkor jelezzük. */
export default function CookieBlockedPanel (): HTMLElement | null {
  if (ClientCookie.isEnabled()) {
    return null
  }

  return (
    <div className={ styles.panel }>
      Az oldal használatához engedélyezni kell a sütiket!
    </div>
  )
}
