import ajax from '@common/ajax/v1/ajax'

import { setLang, addTranslation } from '@common/i18n/v1/translate'
import type { TLanguage } from '@common/i18n/definitions'
import localFontLoader from '@common/localFontLoader/localFontLoader'

import SVGIcon from '@common/components/SVGIcon/SVGIcon'
import ToTop from '@common/components/ToTop/ToTop'

import CookieBlockedPanel from './components/CookieBlockedPanel/CookieBlockedPanel'
import ActivationModal from './components/ActivationModal/ActivationModal'
import PromoForm from './components/PromoForm/PromoForm'
import LoginForm from './components/LoginForm/LoginForm'
import Footer from './components/Footer/Footer'
import Status from './components/Status/Status'
import Menu from './components/Menu/Menu'

import CookieHandler from './CookieHandler'

import init from './init'

import styles from './main.scss'

declare const __DEV__: boolean
declare const __LOCALES__: string
declare const __LANGS__: TLanguage[]

if (__DEV__) {
  ajax.setGlobalErrorHandler((responseText: string) => {
    document.body.innerHTML = responseText
  })
}

// Sajnos kell, mert a rohadt google fontsról ékezet nélkül jön egy ideje.
localFontLoader([ 'Luckiest+Guy' ])

;(async function IIFE () {
await Promise.all(
  __LANGS__.map(async (lang: TLanguage) => {
    // MUSZÁJ külön változóban tartani a fájlnevet, különben syntax error!
    const fileName = `${ lang }-${ lang }.json`

    const commonJson = await import(
      /* webpackMode: "eager" */
      __LOCALES__ + fileName
    )

    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    addTranslation(lang, commonJson.default)

    setLang('hu')
  })
)

CookieHandler.setSourceCookie()

document.body.appendChild(
  <Fragment>
    <Menu />
    <div id="daily-code" />
    <Status />

    <div className={ styles.mainForms }>
      <PromoForm />
      <LoginForm />
    </div>

    <div id="apps-container" className={ styles.appContainer } />
    <div id="devapps-container" className={ styles.appContainer } />
    <Footer />

    <ToTop>
      <SVGIcon fill="#fff" type="caret-top" />
    </ToTop>

    <CookieBlockedPanel />
  </Fragment>
)

init()

  // Ha az url sávban van valami szériaszám, akkor esetleg legacy linkről jött,
  // jöjjön fel aktivációs modal.
if (window.location.pathname.includes('BD-TO0')) {
  document.body.appendChild(<ActivationModal />)
}
})()
