import type { TAppTitles, TLanguageValue, TUgyesedniAppServiceRoutes } from '@langedit/backend/definitions'

import type { TSerial } from '@apps/definitions'

import type { IUgyesedniStatistics } from '@statService/backend/definitions'

import type { TAvailablePlatform, TAvailabilityWithNumber } from '../common/definitions'

export type { TUgyesedniStatistics } from '@statService/backend/definitions'

export type { TAppModules, TLevelOptions as TAppLevelOptions } from '../moduleService/definitions'

export type { TLanguageParts, TUpdatableAppKey } from '@langedit/backend/definitions'

export type { TEnabledLanguages, IPaginatorResponse } from '@ugyesedni/definitions'

export type {
  TAppTitles,
  TLanguageValue,
  TAvailablePlatform,
  TAvailabilityWithNumber,
  TUgyesedniAppServiceRoutes
}

export {
  LANGUAGES_FLAGS,
  AVAILABILITY_NAMES,
  AVAILABLE_PLATFORMS,
  DEFAULT_LANGUAGE_VALUE,
  AVAILABILITY_FLAG_NUMBERS
} from '../common/definitions'

export type TSerials = readonly TSerial[]

export enum EDeleted {
  False = 0,
  True  = 1
}

export type TAvailabilityFlagNumbers = {
  readonly [key in TAvailablePlatform]: number
}

export type TAvailabilityFlagValues = {
  readonly [key in TAvailablePlatform]: boolean
}

export interface IGetByPlatform {
  readonly flag: TAvailablePlatform
}

export interface IAppGetByIdsRequest {
  readonly ids: readonly number[]
}

export enum EAppActive {
  False = 0,
  True  = 1
}

export interface IBaseCategory {
  readonly titles: TLanguageValue
  readonly orderNum: number
}

export interface ICategoryInsert extends IBaseCategory {
  readonly availabilityFlags: number
}

export interface ICategory extends ICategoryInsert {
  readonly id: number
  readonly name: string
  readonly platformName?: string
  readonly weight: number
}

export type TCategory = ICategory & TAvailabilityFlagNumbers

export type TCategories = readonly TCategory[]

interface IDigestCategory extends TCategory {
  /** Mennyi app tartozik a kategóriához. */
  readonly count: number
}

export type TDigestCategories = readonly IDigestCategory[]

export interface IAvailability {
  readonly appId: number
  readonly platformId: number
}

// export const APP_DEV_PHASES = [ DEV_PHASE.development, DEV_PHASE.done ] as const
// eslint-disable-next-line @typescript-eslint/no-magic-numbers
export const APP_DEV_PHASES = [ 1, 2 ] as const

export type TAppDevPhase = typeof APP_DEV_PHASES[number]

export const APP_DEV_PHASE_NAMES: Record<TAppDevPhase, string> = {
  1: 'kész',
  2: 'fejlesztés alatt'
}

export const APP_DEV_PHASE_KEYS = [ 'Done', 'Development' ] as const

export type TAppDevPhaseKey = typeof APP_DEV_PHASE_KEYS[number]

export const APP_DEV_PHASE_VALUES: Record<TAppDevPhaseKey, TAppDevPhase> = {
  /* eslint-disable @typescript-eslint/naming-convention */
  Done: 1,
  Development: 2
  /* eslint-enable @typescript-eslint/naming-convention */
}

export interface IUpdateAppDevPhaseRequest {
  readonly devPhase: TAppDevPhase
}

export interface IBaseApp {
  /** Az app szériaszáma, itt elég a string. */
  readonly serial: string
  /** Az app életkori besorolásának alja. */
  readonly minAge: number
  /** Az app életkori besorolásának teteje. */
  readonly maxAge: number
  readonly availabilityFlags: number
}

export interface IDbApp extends IBaseApp {
  /** [LEGACY] Tároljuk a titles-ből a magyart is, hogy legyen fallback-ünk. */
  readonly name: string
}

export interface IApp extends IDbApp, TAvailabilityFlagNumbers  {
  readonly id: number
  readonly subjectName: TLanguageValue
  readonly titles: TLanguageValue
  readonly longDesc: TLanguageValue
  readonly shortDesc: TLanguageValue
  readonly subTitle: TLanguageValue
  readonly devPhase: TAppDevPhase
}

export type TApps = readonly IApp[]

export interface IGetAllAppsResponse {
  readonly apps: TApps
}
export interface IGetAppsBySerialsRequest {
  readonly serials: readonly string[]
}

export interface IGetAppsBySerialsResponse {
  readonly apps: TApps
}

export interface IAppDigest extends IApp {
  readonly categories: TCategories
}

interface IAppStatistic extends IUgyesedniStatistics {
  readonly name: string
}

export type TAppStatistics = readonly IAppStatistic[]

export interface IAppCategoryRequest {
  readonly id: number
  readonly weight: number
}

export interface IAppRequest extends IBaseApp {
  readonly titles: TLanguageValue
  readonly shortDesc: TLanguageValue
  readonly longDesc: TLanguageValue
  readonly categories: readonly IAppCategoryRequest[]
}

export interface IBaseAppCategory {
  readonly categoryId: number
}

export interface IAppCategory extends IBaseAppCategory {
  readonly id: number
  readonly appId: number
}

export type TAppCategories = readonly IAppCategory[]

export interface IAppCategoryInsertRequest {
  readonly appId: number
  readonly categoryId: number
  readonly platformId: number
  readonly weight: number
}

/** Csomagok. */
export interface IBasePackage {
  readonly name: string
  readonly description: string
}

export interface IPackage extends IBasePackage {
  readonly id: number
  readonly createdAt: string
}

export type TPackages = readonly IPackage[]

interface IDigestPackage extends IPackage {
  /** Mennyi app tartozik ehhez a csomaghoz. */
  readonly count: number
}

export type TDigestPackages = readonly IDigestPackage[]

export interface IPackageRequest extends IBasePackage {
  readonly appIds: number[]
}

export interface IPackageDigest {
  readonly package: IPackage
  readonly apps: TApps
}

export interface IPackageApp {
  readonly name: string
  readonly appIds: number[]
}
export type TPackagesApps  = readonly IPackageApp[]

export interface IAdminAppInitResponse {
  // readonly apps: TApps
  readonly categories: TCategories
}

export enum EAssetType {
  YoutubeLink = 1,
  Image = 2
}

export interface IAssetQuery {
  readonly type: number
  readonly languages: number
  readonly source: string

}

export interface IAppAssetRequest {
  readonly availabilityFlags: number
}

export interface IBaseAppAsset extends IAppAssetRequest {
  readonly appId: number
  readonly assetType: EAssetType
  readonly source: string
}

export interface IAppAsset extends IBaseAppAsset, TAvailabilityWithNumber {
  readonly id: number
}

export type TAppAssets = readonly IAppAsset[]

export interface IGetAppAssetResponse {
  readonly asset: IAppAsset
}

export interface IAppInfo {
  readonly app: IApp
  readonly assets: TAppAssets
  readonly titles: TLanguageValue
  readonly shortDesc: TLanguageValue
  readonly longDesc: TLanguageValue
}

/** Promociós appok. */
export interface IBasePromotionAppRequest {
  readonly startDate: string
  readonly endDate?: string
  readonly availabilityFlags: number
}
export interface IBasePromotionApp extends IBasePromotionAppRequest {
  readonly appId: number
}

export interface IPromotionApp extends IBasePromotionApp, TAvailabilityFlagNumbers {
  readonly id: number
  readonly serial: string
  readonly createdAt: string
}

export type TPromotionApps = readonly IPromotionApp[]

export interface IPromotionAppWithNameAndDesc extends IPromotionApp {
  readonly name: string
  readonly desc: string
}

export type TPromotionAppsWithName = readonly IPromotionAppWithNameAndDesc[]

export interface IPromoAppInitResponse {
  readonly apps: TApps
  readonly promoApps: TPromotionApps
}

export interface IGetSerialsResponse {
  readonly serials: readonly string[]
}

export interface IBaseAppRelease {
  readonly serial: string
}

export interface IAppRelease extends IBaseAppRelease {
  readonly id: number
  readonly createdAt: string
}

export type TAppReleases = readonly IAppRelease[]

/** Routing. */
export enum EAppRoute {
  GetDevApps     = '/api/app/apps/get-dev-apps',
  UpdateDevPhase = '/api/app/apps/update-dev-phase/:serial',
  GetByIds       = '/api/app/apps/get-by-ids',
  GetAll         = '/api/app/admin/apps/get-all',
  GetPage        = '/api/app/admin/apps/get-page',
  GetAllAppIds   = '/api/app/admin/apps/get-all-app-ids',
  GetById        = '/api/app/admin/apps/get/:id',
  Delete         = '/api/app/admin/apps/delete/:id',
  Insert         = '/api/app/admin/apps/insert',
  Modify         = '/api/app/admin/apps/modify/:id',
  GetBySerial    = '/api/app/internal/apps/get-by-serial/:serial',
  GetBySerials   = '/api/app/internal/apps/get-by-serials',
  GetStatistics  = '/api/app/admin/apps/get-statistics',

  Init           = '/api/app/admin/apps/init',
  InitPlayground = '/api/app/apps/init-playground'
}

export enum EAppAssetRoute {
  Delete      = '/api/app/admin/assets/delete/:id',
  GetByAppId  = '/api/app/admin/assets/get-by-app-id/:appId',
  GetById     = '/api/app/admin/assets/get-by-id/:id',
  Insert      = '/api/app/admin/assets/insert/:appId',
  Modify      = '/api/app/admin/assets/modify/:id'
}

export enum EAppReleasesRoute {
  Insert = '/api/app/app-releases/insert/:serial'
}

export enum ECategoryRoute {
  Delete    = '/api/app/admin/categories/delete/:id',
  GetAll    = '/api/app/admin/categories/get-all',
  GetAppIds = '/api/app/internal/categories/:id/app-ids',
  GetById   = '/api/app/admin/categories/get/:id',
  GetPage   = '/api/app/admin/categories/get-page',
  Insert    = '/api/app/admin/categories/insert',
  Modify    = '/api/app/admin/categories/modify/:id'
}

export enum EAppCategoryRoute {
  GetBySerial = '/api/app/admin/app-categories/get/:serial',
  Delete      = '/api/app/admin/app-categories/delete/:serial',
  Insert      = '/api/app/admin/app-categories/insert'
}

export enum EPackageRoute {
  Delete        = '/api/app/admin/packages/delete/:id',
  GetAll        = '/api/app/admin/packages/get-all',
  GetPage       = '/api/app/admin/packages/get-page',
  GetAllDigest  = '/api/app/admin/packages/get-all-digest',
  GetById       = '/api/app/admin/packages/get/:id',
  Insert        = '/api/app/admin/packages/insert',
  Modify        = '/api/app/admin/packages/modify/:id'
}

export enum EWebRoute {
  Init           = '/api/app/web/init'
}

export enum EPromotionAppsRoute {
  // Kívülről hívjuk, ami megmondja, hogy éppen "promociós e", tehát mehet e full-ban.
  Check   = '/api/app/promotion-apps/check/:serial',
  GetAll  = '/api/app/promotion-apps/get-all',

  GetPage = '/api/app/admin/promotion-apps/get-page',
  Delete  = '/api/app/admin/promotion-apps/delete/:id',
  GetById = '/api/app/admin/promotion-apps/get/:id',
  Init    = '/api/app/admin/promotion-apps/init',
  Insert  = '/api/app/admin/promotion-apps/insert',
  Modify  = '/api/app/admin/promotion-apps/modify/:id'
}

// --- WEB
export interface IWebApp extends IApp {
  readonly categoryId: number
}

export type TCategoriesApp = Record<string, TApps>

export interface ICategoryData {
  readonly app: TCategoriesApp
  readonly promotionAppIds: readonly number[]
  readonly titles: readonly (TLanguageValue & { id: number })[]
}

// --- PLAYGROUND

export interface IPlaygroundApp {
  readonly id: number
  readonly name: string
  readonly serial: string
}

export type TPlaygroundApps = readonly IPlaygroundApp[]

export type TPlaygroundData = Record<string, TPlaygroundApps>

export interface IPlaygroundDbApp extends IPlaygroundApp {
  readonly id: number
  readonly orderNum: number
  readonly categoryId: number
}

export interface IGetAppIdsByCategoryIdResponse {
  readonly appIds: readonly number[]
}
