import type { TSoundTestResult } from '@apps/EffectPlayer/definitions'

import type { TMusic } from '@apps/definitions'

import type { ILocation, IStatusResponse } from '../definitions'

import type { IPreloadedAudio } from './preloader/definitions'

export const APPSHELL_LOADED_EVENT = 'System:App:Loaded'

export interface IPreloaderSoundResult {
  readonly audio: IPreloadedAudio
  readonly soundTestResult: TSoundTestResult
}

export interface IAppShellLoadedData {
  readonly status: IStatusResponse | null
  readonly preloaderResult: IPreloaderSoundResult
  readonly location: ILocation
}

export interface ISound {
  readonly name?: string
  readonly link?: string
}

export interface IImprint {
  readonly gfx:       string[]
  readonly code:      string[]
  readonly content:   string[]
  readonly manager:   string[]
  readonly narration: string[]
  readonly music?:    ISound[]
  readonly sound?:    ISound[]
}

interface IMainMusicProps {
  readonly type: TMusic
  readonly volume: number
}

interface IGameMusicPropsVolume {
  readonly volume: number
}

interface IGameMusicProps extends IGameMusicPropsVolume {
  readonly type: TMusic
}

export type TGameMusicProps = IGameMusicPropsVolume | IGameMusicProps | false

export interface IMusic {
  readonly main: IMainMusicProps
  readonly game?: TGameMusicProps | false
}

export const ALLOWED_MODULES_TYPES = [ 'game', 'level' ] as const

export type { TSoundTestResult }

export type { IQueryParams } from '@common/Query/definitions'

export type { TBuildType } from '@root/webpack/v2/definitions'

export type { IRequest, IBaseUgyesedniAppItem } from '@statService/backend/definitions'

export { EDU_BACKGROUND_TYPES, REQUIRED_EDU_MODULAR_OPTION_KEYS } from '@apps/definitions'

export type {
  TSerial, TEngine, TSfxTheme, TDevPhase, TDemoStatus, IUiTranslate,
  TRestrictions, TEduBackground, TGameMusicProps as TBaseGameMusicProps
} from '@apps/definitions'

export type { TLanguage, TLanguages } from '@apps/translator/definitions'

export { EIsFavourite, EModulesRoute, EFavouriteModulesRoute } from '@apps/system/modular/definitions'

export type {
  IIsFavouriteResponse,
  IToggleFavouriteResponse,
  TAdvancedAncientLevelOptions,
  IGetModuleIdByAncientLevelOptionsRequest,
  IGetModuleIdByAncientLevelOptionsResponse
} from '@apps/system/modular/definitions'

export {
  MODULAR_ATTR, MODULE_ID_ATTR, MODULE_TYPE_ATTR,
  EAppPostMessageCommand, SESSION_STORAGE_LEVEL_OPTIONS_KEY
} from '../definitions'

export type { ILocation, TModuleType, IStatusResponse } from '../definitions'

export type {
  ILevelOption,
  TLevelOptions,
  TLevelOptionValue,
  IGetActiveLevelOptionsBySerialResponse,
  IGetLevelOptionsBySerialAndModuleIdRequest,
  IGetLevelOptionsBySerialAndModuleIdResponse
} from '../services/moduleService/definitions'

export { ELevelOptionsRoute } from '../services/moduleService/definitions'

export type { TRequestMethod } from '@common/http/definitions'

export type { IBaseBreadCrumbLevelPage } from '@bc/v2/BaseBreadCrumbLevelPage/definitions'

export { ELoadingEvent } from '@bc/v3/Loading/definitions'
