import trans from '@common/i18n/v1/trans'

import Stars from '../Stars/Stars'

import styles from './SubTitle.scss'

interface IProps {
  readonly textKey?: string
  readonly text?: string
  readonly children?: unknown
}

/**
 * Csillagok közötti alcím szöveg factory-ja.
 * @param props - JSX props {@link IProps}.
 */
export default function SubTitle (props: IProps): HTMLElement {
  return (
    <div className={ styles.subtitle }>
      <Stars side="left" />

      <h3 className="sni-subtitle__text">
        { props.textKey ? trans(props.textKey) : props.text ?? '' }
        { props.children }
      </h3>

      <Stars side="right" />
    </div>
  )
}
