import styles from './FormTitle.scss'

interface IProps {
  readonly text: string
}

/**
 * Egy form címének factory-ja.
 * @param props - JSX props {@link IProps}.
 */
export default function FormTitle (props: IProps): HTMLElement {
  return <h2 className={ styles.title }>{ props.text }</h2>
}
