import styles from './FallbackMessage.css'

const MSG = [
  '[DEV MÓD] Nem jött JSON válasz a szervertől! A fallback JSON-ok vannak használva.',
  '<br />',
  'Ez azért lehet, mert vagy nincs jól bekötve a backend,',
  'vagy mert közvetlenül a webpack-dev-server van futtatva a böngészőben.'
].join('')

/** A fejlesztőknek jelzi, ha DEV módban nem kaptak választ a szervertől. */
export default function FallbackMessage (): HTMLElement {
  return (
    <div
      id="error"
      className={ styles.fallbackMessage }
      onClick={ () => document.querySelector('#error')?.remove() }
      dangerouslySetInnerHTML={ { __html: MSG } }
    />
  )
}
