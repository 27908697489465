import ClientCookie from '@common/Cookie/ClientCookie'

import '@root/css/reset/tags/button.css'
import styles from './Button.scss'

interface IProps {
  readonly text: string
  readonly onClick?: TVoidCallback
}

/**
 * A játéktér egységes gomb komponensének factory-ja.
 * @param props - JSX props {@link IProps}.
 */
export default function Button (props: IProps): HTMLButtonElement {
  const { text, ...rest } = props

  const cn = [ styles.button, ClientCookie.isEnabled() ? '' : styles.disabled ]

  const btn = (
    <button
      className={ cn }
      type="submit"
      { ...rest }
    >
      { text }
    </button>
  )

  return btn
}
