export type { EIcon } from '../Icon/definitions'

export enum EColor {
  Red    = 'red',
  Grey   = 'grey',
  Orange = 'orange',
  Green  = 'green',
  Blue   = 'blue'
}

export enum ESize {
  Small  = 'small',
  Medium = 'medium',
  Large  = 'large'
}
