import type { IPostMessage } from '@common/PostMessageHandler/definitions'
import type { IQueryParams } from '@common/Query/definitions'

import type {
  TLanguageValue,
  TAvailableLanguage,
  TUgyesedniServicesRoutes,
  TUgyesedniAppServiceRoutes,
  TUgyesedniModuleServiceRoutes
} from '@langedit/backend/definitions'

import type { IUser, TUserType, IInstitution, IChildWithLevelCheckerInfo } from './services/userService/definitions'

export {
  UPDATABLE_APP_KEYS
} from '@langedit/backend/definitions'

export type {
  TAppTitles,
  ISearchRequest,
  TLanguageValue,
  TLanguageParts,
  ISearchResponse,
  TUpdatableAppKey,
  ISetPartsRequest,
  ISetAppKeyValuesBySerialRequest,
  IModuleSetAppKeyValuesBySerialRequest
} from '@langedit/backend/definitions'

export type { IPostMessage, TAvailableLanguage }

export const DEFAULT_TAX_RATE     = 27
export const GROSS_TO_NET_RATION  = 1.27 // 1+(27/100)

export const GATEWAY_HOST = 'localhost'
export const GATEWAY_PORT = 3100

export interface IPaginatorRequest {
  readonly actPage: number
  readonly itemsPerPage: number
}

export interface IPaginatorResponse<T extends readonly unknown[]> {
  readonly count: number
  readonly actPage: number
  readonly data: T
}

export interface IPaginationRequest extends IPaginatorRequest {}

export interface IPaginationMetaData extends IPaginationRequest {
  readonly totalCount: number
  readonly pageCount: number
}

export interface IPaginationResponse<T extends readonly unknown[]> {
  readonly metaData: IPaginationMetaData
  readonly data: T
}

// Szervízek közötti header kulcs.
export const INTERNAL_UGYESEDNI_KEY = 'h9whRA1bstZqabycDsMFLV8l'

export type { ISessionDigest } from './services/sessionService/definitions'

export const SOURCE_COOKIE_NAME = 'ugyesedni:source'

export const SESSION_COOKIE_NAME = 'ugyesedni:session'

export const PLATFORM_SOURCES = [ 'family' ] as const

export const SOURCES = [ 'playground', 'admin', 'csillagsuli', ...PLATFORM_SOURCES ] as const

export type TSource = typeof SOURCES[number]

export type TSourceType = TSource | ''

export const VALID_CURRENCIES = [ 'HUF' ] as const

export type TValidCurrency = typeof VALID_CURRENCIES[number]

export const VALID_PAYMENT_LANGUAGES = [ 'HU' ] as const

export type TValidPaymentLanguages = typeof VALID_PAYMENT_LANGUAGES[number]

export type TResponseError = [ code: number, message: string ]

export interface IGetPageQueryParam {
  readonly actPage: number
  readonly itemsPerPage: number
}

export interface IStatusResponse {
  readonly isDemo: boolean
  readonly isBlocked: boolean
  readonly source: TSourceType
}

export enum EGatewayRoute {
  CheckPlatformSession = '/api/platform/check-session',

  CheckSession  = '/api/family/check-session',
  CheckLogin    = '/api/family/check-login',
  UserLogin     = '/api/family/login',
  UserLogout    = '/api/family/logout',

  AdminInit = '/api/admin/init',
  Logout    = '/api/logout'
}

export const RESET_PASSWORD_ROUTE       = '/elfelejtett-jelszo'
export const PROFILE_CONFIRMATION_ROUTE = '/profil-megerosites'

export type TSetTitleResult<T extends TObject> = readonly (T & { titles: TLanguageValue })[]

export type TCommunicatorCommand = (
  `ugyesedniAppService:${ TUgyesedniAppServiceRoutes }` |
  `ugyesedniServices:${ TUgyesedniServicesRoutes }` |
  `ugyesedniModuleService:${ TUgyesedniModuleServiceRoutes }` |
  'translations:search'
)

/**
 * Az Ügyesedni appok esetén lehetséges és támogatott nyelvek.
 * A sorrendet ne változtasd, mert logikailag és időrendben is így következnek.
 */
export const ENABLED_LANGUAGES: Readonly<TNonEmptyArray<TAvailableLanguage>> = [
  'hu',  // 1.
  'en',  // 2.
  'es',  // 3.
  'ro',  // 4.
  'de',  // 5.
  'sk'   // 6.
]

export type TEnabledLanguages = typeof ENABLED_LANGUAGES[number]

/** Ha modulárisan van indítva az app, akkor ez az attribútum kerül a html tagre. */
export const MODULAR_ATTR = 'modular'

/** Ha modulárisan van indítva az app, akkor ez mondja meg, hogy pályaválasztó vagy játék indult modulárisan. */
export const MODULE_TYPE_ATTR = 'module-type'

export const MODULE_TYPES = [ 'level', 'game', null ] as const

export type TModuleType = typeof MODULE_TYPES[number]

/** Ha modulárisan van indítva az app, akkor az ehhez tartozó érték mondja meg melyik modul. */
export const MODULE_ID_ATTR = 'module-id'

/** SessionStorage-ban tárolsz szintopciók kulcsa. */
export const SESSION_STORAGE_LEVEL_OPTIONS_KEY = 'levelOptions'

/** A window.location használata helyett a system-nek meg tudjuk adni, hogy milyen URL paraméterek alapján működik az app. */
export interface ILocation {
  protocol: string
  hostname: string
  port: string
  pathname: string
  query: IQueryParams
}

export const EXIT_MODES = [ 'main', 'level' ] as const
export type TExitMode = typeof EXIT_MODES[number]

export const DEFAULT_EXIT_MODE: TExitMode  = 'level'

export enum EAppPostMessageCommand {
  Exit                 = 'Command:Exit',
  GameOver             = 'Command:GameOver',
  SendStat             = 'Command:SendStat',
  StartGame            = 'Command:StartGame',
  Preloaded            = 'Command:Preloaded',
  Preloading           = 'Command:Preloading',
  GameStarted          = 'Command:GameStarted',
  LevelSelected        = 'Command:LevelSelected',
  SoundSystemChange    = 'Command:SoundSystemChange',
  SendPreloadStatus    = 'Command:SendPreloadStatus',
  SendSoundSystemProps = 'Command:SendSoundSystemProps'
}

export interface ISoundSystemChangeProps {
  readonly isSfxEnabled: boolean
  readonly isMusicEnabled: boolean
  readonly isNarratorEnabled: boolean
}

export const THEME_LABEL_NAMES = [
  'zoo', 'kitchen', 'city', 'house and garden',
  'class', 'earth', 'research station', 'bible'
] as const

type TThemeLabelNames = typeof THEME_LABEL_NAMES

export type TThemeLabelName = TThemeLabelNames[number]

export const PLANET_IMAGE_INDEXES = [
  // eslint-disable-next-line @typescript-eslint/no-magic-numbers -- Jajj, Istenkém.
  1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20
] as const

export type TPlanetImageIndex = typeof PLANET_IMAGE_INDEXES[number]

export interface ISoundSystemChangePostMessage extends IPostMessage<ISoundSystemChangeProps> {}

export interface IUserLoginData {
  userType: TUserType | null
  hasSubscription: boolean
  detail: IInstitution | IUser | IChildWithLevelCheckerInfo | null
}
