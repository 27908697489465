import getTitleSize from './utils/getTitleSize'
import getName      from './utils/getName'

import styles from './AppTitle.scss'

interface IProps {
  readonly title: string
  readonly class?: string
}

/**
 * Az app nevét megjelenítő komponens.
 * @param props - JSX props {@link IProps}.
 */
export default function AppTitle (props: IProps): HTMLElement {
  const finalName = getName(props.title)

  const title: HTMLParagraphElement = (
    <p
      className={ [ styles.title, props.class ?? '' ] }
      data-size={ getTitleSize(finalName) }
      dangerouslySetInnerHTML={ { __html: finalName } }
    />
  )

  return title
}
