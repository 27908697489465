import type { TPlaygroundApps } from '../../definitions'

import SubTitle from '../SubTitle/SubTitle'

import Apps from '../Apps/Apps'

interface IProps {
  readonly name: string
  readonly apps: TPlaygroundApps
}

/**
 * Egy egész kategória, annak a címe és a hozzátartozó appok factory-ja.
 * @param props - JSX props {@link IProps}.
 */
export default function Category (props: IProps): HTMLElement {
  return (
    <div style={ { padding: '20px' } }>
      { props.name.length ? <SubTitle text={ props.name } /> : null }
      <Apps apps={ props.apps } />
    </div>
  )
}
