import './Link.scss'

interface IProps {
  readonly href: string
  readonly target?: '_blank'
  readonly onClick?: ((e: Event) => void) | undefined
  readonly children?: string
}

/**
 * A játéktér saját link komponensének factory-ja.
 * @param props - JSX props {@link IProps}.
 */
export default function Link (props: IProps): HTMLAnchorElement {
  const { children, onClick, ...rest } = props

  return (
    <a
      className="pg-link"
      {
        ...(
          rest.target === '_blank'
            ? { rel: 'noreferrer' }
            : {}
        )
      }
      { ...rest }
      {
        ...(onClick ? { onClick } : {})
      }
    >
      { children }
    </a>
  )
}
