import trans from '@common/i18n/v1/trans'

import Link from '../Link/Link'

/**
 * Hibás formvalidációkor (a szerver valami hibával tért vissza), ezt a komponenst példányosítjuk segítségképp.
 */
export default function HelpPanel (): HTMLElement {
  return (
    <div id="helpPanelContainer">
      <Link href="https://www.youtube.com/watch?v=vnW6_gmBhD8" target="_blank">
        { trans('HelpPanel.click') }
      </Link>
    </div>
  )
}
