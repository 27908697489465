import globalEvents from '@common/globalEvents/globalEvents'

import DOM from '@common/DOM/DOM'

import styles from './DailyCode.scss'

interface IProps {
  readonly code: string
}

const MIN_SCROLL_TOP = 160

/**
 * A bejelentkezett felhasználó napi kódját megjelenítő komponens factory-ja.
 * @param props - JSX props {@link IProps}.
 */
export default function DailyCode (props: IProps): HTMLElement {
  const fc: HTMLElement = <div className={ styles.floatingCode }>{ props.code }</div>

  globalEvents.onScroll(() => {
    const scrollTop = DOM.getScrollTop()

    if (scrollTop >= MIN_SCROLL_TOP) {
      fc.classList.add(styles.visible)
    }
    else {
      fc.classList.remove(styles.visible)
    }
  })

  return (
    <div className={ styles.dailyCode }>
      <div className={ styles.container }>
        <div className={ styles.title }>Napi kód:</div>
        <div className={ styles.code }>{ props.code }</div>
      </div>

      { fc }
    </div>
  )
}
