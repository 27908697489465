import trans from '@common/i18n/v1/trans'

import { EHttpStatusCode } from '@common/http/definitions'

import init from '../../init'

import HelpPanel from '../HelpPanel/HelpPanel'
import FormTitle from '../FormTitle/FormTitle'
import Button    from '../Button/Button'
import Error     from '../Error/Error'
import Input     from '../Input/Input'
import Form      from '../Form/Form'

/** Promóciós kód aktiválásához szükséges komponens factory-ja. */
export default function PromoForm (): HTMLElement {
  const errorEl: HTMLSpanElement = <Error />

  /**
   * Ha valami hiba történt az ajax közben, akkor hívódó callback.
   * @param _tmp - Nem használt paraméter.
   * @param xhr  - Az ajax object.
   */
  function error (_tmp: unknown, xhr: XMLHttpRequest): void {
    if (xhr.status === EHttpStatusCode.BadRequest) {
      errorEl.innerHTML = ''

      errorEl.appendChild(
        <Fragment>
          <span>{ trans('PromoForm.invalid') }</span>
          <HelpPanel />
        </Fragment>
      )
    }
    else {
      errorEl.innerHTML = trans('server.error')
    }
  }

  /** Sikeres ajax válasz esetén hívódó callback. */
  function success (): void {
    errorEl.innerHTML = ''

    init()
  }

  const promoForm = (
    <Form action="/api/playground/promo" error={ error } success={ success }>
      <FormTitle text={ trans('PromoForm.title') } />
      <Input name="promotion_code" required placeholder={ trans('PromoForm.password') } type="password" />
      <Button text={ trans('PromoForm.submit') } />
      { errorEl }
    </Form>
  )

  return promoForm
}
