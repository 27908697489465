import { is } from '@common/is/is'

import ajax from '@common/ajax/v1/ajax'

import { URL } from '@common/URL/URL'

import FallbackMessage from './components/FallbackMessage/FallbackMessage'

import type { IInitResponse } from './definitions'

import signIn   from './signIn'
import signOut  from './signOut'

import User from './User'
import CookieHandler from './CookieHandler'

declare const __DEV__: boolean

const FALLBACK_DATA = __DEV__ ? require('./fallbackData.json') : []

/** A struktúra felépülése után az init felszól a szervernek az adatokért. */
export default function init (): void {
  const url = `${ __DEV__ ? 'http://localhost:3100' : '' }/api/playground/init${ window.location.search }`

  ajax(
    URL.make(url, { source: 'playground' }),
    {
      withCredentials: true,
      /**
       * Sikeres ajax esetén hívódó callback.
       * @param res - A szerver válasza.
       */
      success: (res: IInitResponse) => {
        if (__DEV__ && !is.object(res)) {
          document.body.appendChild(<FallbackMessage />)
        }

        const { data: responseData, session } = res

        const { identifier, login } = session

        // Beállítjuk neki a kapott cookie-t.
        CookieHandler.setCookie(identifier)

        const {
          // isLoggedIn = false,
          attachedApps = [],
          dailycode, // FALLBACK_DATA.map((app) => app.id)
          data = FALLBACK_DATA,
          theme = 'default'
        } = responseData ?? {}

        // Ha a szerver valamiért üres stringet küldene.
        const normalTheme = is.empty(theme.trim()) ? 'default' : theme

        User.isLoggedIn = Boolean(login)
        User.dailyCode  = dailycode
        User.theme      = normalTheme

        if (login) {
          signIn(data, attachedApps, dailycode, normalTheme)
        }
        else {
          signOut(data)
        }
      },
      /** AJAX hiba esetén fallback-et mutatunk. */
      error: (): false | void => {
        signOut(FALLBACK_DATA)

        if (__DEV__) {
          // Ha false-al lépünk vissza az error-ból, akkor a globalErrorHandler is lekezeli.
          // Igy ha jön hiba a backendtől, akkor azt le tudjuk kezelni fejlesztéskor.
          return false
        }
      }
    }
  )
}
