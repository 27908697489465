import DOM from '@common/DOM/DOM'

import ajax from '@common/ajax/v1/ajax'

import { Query } from '@common/Query/Query'

import CookieHandler from '../../CookieHandler'
import type { IInitResponse } from '../../definitions'

import Button from '../Button/Button'
import Error from '../Error/Error'
import Input from '../Input/Input'

import styles from './ActivationModal.scss'

/**
 * Aktivációs modal factory-ja.
 * Ezt a komponenst akkor mutatjuk, amikor a felhasználó még nincs bejelentkezve és nincs aktivált promókódja sem, de az
 * url sávban ügyesedni szériaszám szerepel.
 */
export default function ActivationModal (): HTMLElement {
  const modalContainer: HTMLElement = <div className={ styles.modalContainer } />

  const codeEl: HTMLInputElement = <Input placeholder="Promóciós kód" type="password" />
  const errorEl: HTMLSpanElement = <Error />

  /** Bezáráskor hívódó callback. */
  function onClickClose (): void {
    DOM.remove(modalContainer)
  }

  /** App indítása. */
  function startApp (): void {
    onClickClose()

    const serial = Query.decode(window.location.search).serial?.toString() ?? window.location.pathname

    if (serial?.length && (serial !== '/')) {
      window.location.href = `/apps/${ serial }/`
    }
  }

  /**
   * Az adott app indítása.
   * @param res - A szervertől kapott válasz.
   */
  function onSuccessPromo (res: IInitResponse): void {
    const { session } = res
    const { identifier } = session

    CookieHandler.setCookie(identifier)

    startApp()
  }

  /** Az aktiváló gomb megnyomásakor hívódó callback. */
  function onClickActivate (): void {
    ajax(
      '/api/playground/promo',
      {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        data: { promotion_code: codeEl.value },
        success: onSuccessPromo,
        /** AJAX hiba esetén hívódó callback. */
        error: () => {
          errorEl.innerHTML = 'Nem érvényes promóciós kód.'
        }
      }
    )
  }

  modalContainer.appendChild(
    <div className={ styles.modal }>
      <div className={ styles.content }>
        <div className={ styles.header }>
          <div className={ styles.title }>Aktiválja promóciós kódját</div>
        </div>

        <div className={ styles.body }>
          { codeEl }
          { errorEl }
        </div>

        <div className={ styles.footer }>
          <Button text="Kód aktiválása" onClick={ onClickActivate } />
          <Button text="Nincs promóciós kódom" onClick={ startApp } />
          <Button text="Bezárás" onClick={ onClickClose } />
        </div>
      </div>
    </div>
  )

  return modalContainer
}
