// @ts-nocheck
/* eslint-disable no-undef */

/**
 * @typedef {Object} IBaseGlobals
 * @property {boolean} isDev  - Fejlesztői módban vagyunk?
 * @property {boolean} isProd - Production módban vagyunk?
 * @property {boolean} isTest - Tesztkörnyezetben vagyunk?
 * @property {boolean} isCordova - Cordova build lett?
 */

/**
 * A webpack-től kapott globális konstans értékeink.
 * Az itt jelen levő értékeket projecttől függetlenül, minden környezetben megkapjuk.
 * Ezt lehet felülcsapni project-specifikusan.
 * @example
 * import baseGlobals from '@root/typescript/globals'
 *
 * const globals = {
 *   ...baseGlobals,
 *   myMagicConstant: __MY-MAGIC-CONSTANT__
 * }
 *
 * export default globals
 * @type {IBaseGlobals}
 */
const baseGlobals = {
  isDev: __DEV__,
  isProd: __PROD__,
  isTest: __TEST__,
  isCordova: __CORDOVA__
}

module.exports.baseGlobals = baseGlobals
