enum ETitleSize {
  Small = 'small',
  Big   = 'big'
}

const TOO_LONG_NAME_LENGTH = 14

/**
 * Az app nevét tároló konténer méretét adja vissza az app neve alapján.
 * @param name - Az app neve.
 */
export default function getTitleSize (name: string): ETitleSize {
  return name.length > TOO_LONG_NAME_LENGTH ? ETitleSize.Big : ETitleSize.Small
}
