/* eslint-disable max-lines */
import type {
  TThemeLabelName,
  IPaginatorResponse,
  IPaginationRequest,
  IPaginationResponse
} from '@ugyesedni/definitions'

import type {
  TSerial,
  TLanguageValue,
  TAvailableLanguage,
  IBaseSetAppKeyValuesBySerialRequest
} from '@langedit/backend/definitions'

import type { TRawLevelOptions, IBaseRawLevelOption } from '@apps/testers/levelCombinationsCollector/definitions'

import type { TSetTitleResult } from '../common/TitleHandler/definitions'

import type { IApp, TApps } from '../appService/definitions'

import type { TSubject, TDifficulty, TYearPeriod, TChildrenGrade, TAvailablePlatform } from '../common/definitions'

import type { IChild } from '../userService/definitions'

export type {
  IUser
} from '../userService/definitions'

export {
  SUBJECTS,
  EBindValue,
  DIFFICULTIES,
  PLATFORM_LANGS,
  DEFAULT_LANGUAGE_VALUE,
  AVAILABILITY_FLAG_NUMBERS,
  SELECTABLE_CHILDREN_GRADES,
  APP_IDS_WITHOUT_LEVEL_SELECTOR
} from '../common/definitions'

export type {
  TSubject,
  TDifficulty,
  TYearPeriod,
  TChildrenGrade,
  IPaginationRequest,
  TAvailablePlatform,
  IPaginationResponse }

export enum ECacheKey {
  PartsTree = 'PartsTree',
  Apps      = 'Apps'
}

export enum EState {
  Active   = 1,
  Inactive = 0
}
// Szándékosan nem readonly!
export type TAppsMap = Record<number, IApp>

export const CUSTOM_APP_DESCRIPTION_KEY       = 'customDescription'
export const DEFAULT_ROUTE_MODULE_COUNT       = 10
export const CUSTOM_APP_DESCRIPTION_TITLE_KEY = 'customTitle'

export const DEFAULT_EMPTY_LANGUAGE_VALUE: TLanguageValue = {
  de: '',
  en: '',
  es: '',
  hu: '',
  ro: '',
  sk: ''
}

export enum EExperienceSpaceRoute {
  Init = '/api/module/experience-space/init/:routeId'
}

export enum EFavouriteModulesRoute {
  IsFavourite     = '/api/module/favourite-modules/is-favourite',
  ToggleFavourite = '/api/module/favourite-modules/toggle-favourite',
  GetByUser       = '/api/module/favourite-modules/by-user'
}

export enum EAppDescriptionsRoute {
  Get           = '/api/module/admin/app-descriptions/get',
  Update        = '/api/module/admin/app-descriptions/update',
  GetStatus     = '/api/module/admin/app-descriptions/get-status',
  GetByModuleId = '/api/module/app-descriptions/get-by-module-id/:moduleId'
}

export enum EStatusRoute {
  GetModuleIdSerialPairsByRouteId = '/api/module/status/get-module-id-serial-pairs-by-route-id/:id', // Nem kell az 'admin' bele, mert kívülről hívjuk

  ResetByAppId                    = '/api/module/admin/status/reset-by-app-id',
  GetModuleEditorData             = '/api/module/admin/status/get-module-editor-data/:appId',
  SendLevelCombinations           = '/api/module/admin/status/send-level-combinations',
  GetAppsWithActiveModules        = '/api/module/admin/status/get-apps-with-active-modules'
}

export enum EModuleFinderRoute {
  Search                = '/api/module/module-finder/search',
  SearchPreview         = '/api/module/module-finder/search-preview',
  GetLabels             = '/api/module/module-finder/get-labels'
}

export enum ELabelingRoute {
  Insert = '/api/module/admin/labeing/insert/:appId',
  Delete = '/api/module/admin/labeing/delete/:appId'
}

export enum ELevelOptionsRoute {
  Update                           = '/api/module/admin/level-options/update',
  GetActiveBySerial                = '/api/module/level-options/active-by-serial/:serial',
  GetById                          = '/api/module/admin/level-options/get-by-id/:id',
  GetByAppId                       = '/api/module/admin/level-options/get-by-app-id/:appId',
  DeleteById                       = '/api/module/admin/level-options/delete-by-id/:id',
  GetTranslatedLevelOptionsByAppId = '/api/module/admin/level-options/get-translated-level-options-by-app-id/:appId',

  GetBySerialAndModuleId           = '/api/module/level-options/get-level-options'  // Nem kell az 'admin' bele, mert kívülről hívjuk
}

export enum ELabelsRoute {
  Insert            = '/api/module/admin/labels/insert',
  Update            = '/api/module/admin/labels/update/:id',
  GetById           = '/api/module/admin/labels/get-by-id/:id',
  DeleteById        = '/api/module/admin/labels/delete-by-id/:id',
  GetByType         = '/api/module/admin/labels/get-by-type/:labelType',
  GetTree           = '/api/module/internal/label/get-tree',
  GetParentSuitable = '/api/module/admin/labels/get-parent-suitable',
  GetEditorData     = '/api/module/admin/labels/get-editor-data',

  GetUsedThemes           = '/api/module/labels/get-used-themes',
  GetUsedThemesWithAppIds = '/api/module/labels/get-used-themes-with-app-ids'
}

export enum EModulesRoute {
  DeleteById                  = '/api/module/admin/modules/delete-by-id',
  GetByAppId                  = '/api/modules/modules/get-by-app-id/:appId',
  GetById                     = '/api/module/admin/modules/get-by-id/:id',
  GetByIdSearch               = '/api/module/admin/modules/get-by-id-search/:id',
  GetBySearch                 = '/api/module/admin/modules/get-by-search',
  GetBySerialAndLevelOptions  = '/api/module/modules/by-serial-and-level-options/:serial',
  GetIdByAncientLevelOptions  = '/api/module/modules/get-id-by-ancient-level-options',
  GetInfos                    = '/api/module/internal/modules/get-infos',
  GetLabelTypesByModuleIds    = '/api/module/internal/modules/get-label-types-by-module-ids',
  GetModuleableAppIds         = '/api/module/internal/modules/get-moduleable-app-ids',
  GetModuleCountInfo          = '/api/module/admin/modules/get-module-count-info',
  GetModuleCountInfoBySerial  = '/api/module/internal/modules/get-module-count-info-by-serial/:serial',
  GetTrainingData             = '/api/module/internal/modules/get-training-data',
  GetTranslatedModulesBy      = '/api/module/admin/modules/get-translated-modules-by/:appId',
  GetTranslatedModulesPageBy  = '/api/module/admin/modules/get-translated-modules-page-by',
  Update                      = '/api/module/admin/modules/update/:id'
}

export enum ERoutesRoute {
  AreSendable                       = '/api/module/internal/routes/are-sendable',
  DeleteById                        = '/api/module/admin/routes/delete-by-id',
  DeleteModule                      = '/api/module/admin/routes/delete-module',
  Get                               = '/api/module/routes/get',
  GetById                           = '/api/module/admin/routes/get-by-id/:id',
  GetDevelopmentAreasWithModuleIds  = '/api/module/internal/routes/development-areas-with-module-ids/:id',
  GetGradePeriods                   = '/api/module/admin/routes/get-grade-periods',
  GetLabelsWithModuleIds            = '/api/module/internal/routes/labels-with-moduleIds/:id/:labelId',
  GetLevelCheckerInfos              = '/api/module/internal/routes/get-level-checker-infos',
  GetLevelCheckerRouteFilters       = '/api/module/routes/get-level-checker-route-filter',
  GetLevelCheckers                  = '/api/module/internal/routes/get-level-checkers',
  GetLevelCheckersByGradePeriod     = '/api/module/internal/routes/get-level-chekcers-by-grade-period',
  GetMatchingLevelChecker           = '/api/module/routes/get-matching-level-checker',
  GetModuleDescriptions             = '/api/module/internal/routes/get-module-descriptions/:id',
  GetPage                           = '/api/module/admin/routes/get-page',
  GetRouteAppModules                = '/api/module/admin/routes/get-route-app-modules',
  GetRoutesDetails                  = '/api/module/internal/routes/get-routes-details',
  GetSubjectsWithLabelNodes         = '/api/module/internal/routes/get-subjects-with-label-nodes/:id',
  GetWithModulesById                = '/api/module/routes/get-with-modules-by-id/:id',
  GetWithModulesByIdAdmin           = '/api/module/admin/routes/get-with-modules-by-id/:id',
  GetDigestById                     = '/api/module/routes/get-digest-by-id/:id',
  Insert                            = '/api/module/admin/routes/insert',
  InsertModule                      = '/api/module/admin/routes/insert-module',
  SwapModules                       = '/api/module/admin/routes/swap-modules/:id',
  Update                            = '/api/module/admin/routes/update/:id'
}

export enum EPairedLabelTypesRoute {
  Insert = '/api/module/admin/paired-label-types/insert',
  GetAll = '/api/module/admin/paired-label-types/get-all',
  Delete = '/api/module/admin/paired-label-types/delete'
}

export enum ERouteLabelsRoute {
  GetAll      = '/api/module/route-labels/get-all',
  Insert      = '/api/module/admin/route-labels/insert',
  Update      = '/api/module/admin/route-labels/update',
  GetById     = '/api/module/admin/route-labels/get-by-id/:id',
  DeleteById  = '/api/module/admin/route-labels/delete-by-id/:id',
  GetByType   = '/api/module/admin/route-labels/get-by-type/:type',

  GetLevelCheckerInfos = '/api/module/internal/route-labels/get-level-checker-infos'
}

export enum ERouteLabelTypesRoute {
  GetAll     = '/api/module/admin/route-label-types/get-all',
  Insert     = '/api/module/admin/route-label-types/insert',
  Update     = '/api/module/admin/route-label-types/update',
  GetById    = '/api/module/admin/route-label-types/get-by-id',
  DeleteById = '/api/module/admin/route-label-types/delete-by-id'
}

export enum EModuleImagesRoute {
  DeleteById        = '/api/module/admin/module-images/delete-by-id',
  InsertByUpload    = '/api/module/admin/module-images/insert-by-upload/:appId',
  InsertBase64Image = '/api/module/admin/module-images/insert-base64-image'
}

export enum EUserRoutesRoute {
  Get                = '/api/module/user-routes/get',
  Insert             = '/api/module/user-routes',
  Update             = '/api/module/user-routes/:id',
  Duplicate          = '/api/module/user-routes/duplicate/:routeId',
  DeleteByUserRoutes = '/api/module/user-routes/delete-by-user-routes'
}

export enum ERouteHandlingRoute {
  CreateRouteByThemeAndGrade  = '/api/module/route-handling/create-route-by-theme-and-grade'
}

export enum EWholeAppsRoute {
  InitFinder  = '/api/module/whole-apps/init-finder',
  Search      = '/api/module/whole-apps/search'
}

export enum EGeneratedRoutesRoute {
  /** Az éppen bejelentkezett felhasználó generáltat új útvonalat.  */
  Generate = '/api/module/generated-routes/generate',
  /** Az éppen bejelentkezett felhasználó által generáltatott útvonalak lekérdezése.  */
  Get = '/api/module/generated-routes/by-user',
  /** Az úvonalösszerakónál felhasznált ajánlott modulok lekérdezése. */
  GenerateRecommendableModules = '/api/module/generated-routes/recommendations/:userIdentifier',
  /** Megmondja, hogy az éppen bejelentkezett felhasználó generált-e már útvonalat. */
  HasGenerated = '/api/module/generated-routes/has-generated',
  /** Elmenti és kiküldi a generált útvonalat az adott gyereknek. */
  SaveAndSend = '/api/module/generated-routes/save-and-send/:userIdentifier'
}

export enum ELevelOptionType {
  Regular = 1,
  Special = 2
}

export enum ELevelOptionIsPrimary {
  True  = 1,
  False = 0
}

// A rendszerben megtalálható címkék típusai.
// Korábban ez nem volt típusos, hanem dinamikusan lehetett
// felvenni, módosítani és törölni. Mivel ezekre a funkciókra nem
// igazán van/volt szükség – csak egy felesleges indirekciót hozott –
// ezért kerültek át ezek ide és legalább így fixen típusbiztos lesz.
export const LABEL_TYPES = [
  'gameType',
  'developmentArea',
  'theme',
  'subject',
  'educationalMaterial'
] as const

export type TLabelType = typeof LABEL_TYPES[number]

/** Meghatározza, hogy az egyes címketípusoknak típusú „szülője” lehet.  */
export const POSSIBLE_LABEL_PARENT_TYPES: Record<TLabelType, TLabelType[]> = {
  developmentArea: [],
  educationalMaterial: [ 'educationalMaterial', 'subject' ],
  gameType: [],
  subject: [ 'subject' ],
  theme: []
}

export interface ITranslatedLabelType {
  readonly labelType: TLabelType
  readonly titles: TLanguageValue
}

export type TTranslatedLabelTypes = readonly ITranslatedLabelType[]

export interface ITranslatedSubject {
  readonly subject: TSubject
  readonly titles: TLanguageValue
}

export type TTranslatedSubjects = readonly ITranslatedSubject[]

export interface IBaseGradePeriod {
  readonly grade: TChildrenGrade
  readonly period: TYearPeriod
}

export type TBaseGradePeriods = readonly IBaseGradePeriod[]

export interface IGradePeriod extends IBaseGradePeriod {
  readonly id: number
}

export type TGradePeriods = readonly IGradePeriod[]

export interface ITranslatedGradePeriod extends IGradePeriod {
  readonly titles: TLanguageValue
}

export interface IBaseGradeDifficulty {
  readonly grade: TChildrenGrade
  readonly difficulty: TDifficulty
}

// Ahhoz, hogy a nyelvesítés sokkal verzatilisebb legyen, be kellett vezetni egy ilyen típust.
// Ez lehetőség nyújt arra, hogy akár a sima IGradeDifficulty vagy az IModuleGradeDifficulty
// is nyelvesíthető legyen ugyanazzal a függvénnyel. Természetesen bármely más egyéb interface-re
// is működéképes feltéve, hogy annak van grade és difficulty propertyje.
export type TTranslatedGradeDifficulty<T extends IBaseGradeDifficulty = IBaseGradeDifficulty>
  = T & { titles: TLanguageValue }

export type TTranslatedGradeDifficulties<T extends IBaseGradeDifficulty = IBaseGradeDifficulty>
  = readonly TTranslatedGradeDifficulty<T>[]

export type TTranslatedGradePeriod<T extends IBaseGradePeriod = IBaseGradePeriod>
  = T & { titles: TLanguageValue }

export type TTranslatedGradePeriods<T extends IBaseGradePeriod = IBaseGradePeriod>
  = readonly TTranslatedGradePeriod<T>[]

export interface IBaseRouteGradePeriod {
  readonly routeId: number
  readonly gradePeriodId: number
}

export type TBaseRouteGradePeriods = readonly IBaseRouteGradePeriod[]

export interface IRouteGradePeriod extends IGradePeriod, IBaseRouteGradePeriod {
  readonly createdAt: string
}

export type TRouteGradePeriods = readonly IRouteGradePeriod[]

export interface IGetGradePeriodsResponse {
  readonly gradePeriods: TTranslatedGradePeriods<IGradePeriod>
}

export interface IGradeDifficulty extends IBaseGradeDifficulty {
  readonly id: number
}

export type TGradeDifficulties = readonly IGradeDifficulty[]

export interface IModuleIdWithGradeId {
  readonly moduleId: number
  readonly gradeDifficultyId: number
}

export type TModuleIdWithGradeDifficultyIds = readonly IModuleIdWithGradeId[]

export interface IBaseModuleGradeDifficulty extends IGradeDifficulty {
  readonly moduleId: number
}

export interface IModuleGradeDifficulty extends IBaseModuleGradeDifficulty {
  readonly gradeDifficultyId: number
  readonly createdAt: string
}

export type TModuleGradeDifficulties = readonly IModuleGradeDifficulty[]

// [MÁGUS]: nem igazán tudom, hogy ez pontosan hova kerül,
//  ezért ezt ide tettem. Lehet refaktorálni, de az adat formája
//  maradjon meg.
//
//  Ezt a statService használja, ott kell megfelelni.
export interface IRouteDetails {
  readonly id: number
  readonly name: string
  readonly moduleIds: readonly number[]
  readonly subject: TSubject | null
  readonly themes: readonly TThemeLabelName[]
}

export type TRouteDetails = readonly IRouteDetails[]

export interface IGetRoutesDetailsByIdsRequest {
  readonly ids: readonly number[]
}

export interface IGetRoutesDetailsByIdsResponse {
  readonly routesDetails: TRouteDetails
}

export interface IBaseRoute {
  /** Útvonal megnevezése. */
  readonly name: string
  /** Leírás. */
  readonly description: string
  /** A tantárgy, szintfelmérőrő esetén. */
  readonly subject: TSubject | null
}

export interface IInsertRoute extends IBaseRoute {
  /** A létrehozó felhasználó azonosítója, HA egyéni útvonalról van szó. */
  readonly userIdentifier: string | null
  /** Generált útvonal-e. */
  readonly isGenerated: 0 | 1
}

export interface IRoute extends IInsertRoute {
  readonly id: number
  readonly createdAt: string

  /** Az útvonalban lévő modulok száma. */
  readonly moduleCount: number
}

export type TRoutes = readonly IRoute[]

export interface IRouteWithModuleCount extends IRoute {
}

export type TRoutesWithModuleCounts = readonly IRouteWithModuleCount[]

export interface IDigestRoute extends IRoute {
  readonly gradePeriods: TTranslatedGradePeriods<IRouteGradePeriod>
}

export type TDigestRoutes = readonly IDigestRoute[]

export interface IGetRouteByIdResponse {
  readonly route: IDigestRoute
}

export interface IRoutesGetResponse {
  readonly routes: TRoutes
}

export interface IRoutesGetPageRequest {
  readonly actPage: number
  readonly itemsPerPage: number
  readonly search?: string
  readonly isGauge?: boolean
  readonly isInactiveModuleFilter?: boolean
}

interface IGetPageRoute extends IDigestRoute {
  readonly moduleCount: number
  readonly hasInactiveModule: boolean
}

export type TGetPageRoutes = readonly IGetPageRoute[]

export interface IRoutesGetPageResponse extends IPaginatorResponse<TGetPageRoutes> {}

export interface IGetLevelCheckersByGradePeriodRequest {
  readonly grade: TChildrenGrade
  readonly period: TYearPeriod
}

export interface IGetLevelCheckersByGradePeriodResponse {
  readonly routes: TRoutes
}

export interface IGetAppsWithActiveModulesResponse {
  readonly apps: TApps
}

export interface IInsertRouteRequest extends IBaseRoute {
  readonly gradePeriodIds: readonly number[]
}

export interface IUpdateRouteRequest extends IInsertRouteRequest {}

export interface IGetLevelCheckerParams {
  readonly subject: TSubject
  readonly gradePeriodId: number
}

export interface IGetMatchingLevelCheckerResponse {
  readonly id: number | null
}

export interface IModuleId {
  readonly moduleId: number
}

export type TModuleIds = readonly IModuleId[]

export interface IAppIdWithSerial {
  readonly serial: TSerial
  readonly appId: number
}

export type TAppIdsWithSerials = readonly IAppIdWithSerial[]

export interface ISerialModuleIdPair extends IModuleId, IAppIdWithSerial {}

export type TSerialModuleIdPairs = readonly ISerialModuleIdPair[]

interface IRouteAppModule extends ISerialModuleIdPair {
  readonly appName: string
}

export type TRouteAppModules = IRouteAppModule[] // direkt nem readonly`

export interface IDeleteRouteByIdRequest {
  readonly id: number
}

export interface IInsertModuleToRouteRequest extends IModuleId {
  readonly id: number
}

export interface IDeleteModuleFromRouteRequest extends IInsertModuleToRouteRequest {
  readonly orderNum: number
}

interface IBaseModule {
  /** Modulcím. */
  readonly title: string
  /** Modulleírás. */
  readonly description: string
}

export interface IUpdateModule extends IBaseModule {
  /** A modul elérhetőségi flagje. */
  readonly availabilityFlags: number
}

export interface INewModule extends IBaseModule {
  /** A modul elérhetőségi flagje. */
  readonly availabilityFlags: number
  readonly appId: number
}

export type TBaseRawLevelOptions = readonly IBaseRawLevelOption[]

interface INewRawModule extends INewModule {
  readonly levelOptionIds: readonly number[]
  readonly levelOptions: TBaseRawLevelOptions
}

export type TNewModules = INewModule[]

export interface IModule extends INewModule {
  readonly id: number
  readonly createdAt: string
  readonly isInRoute?: boolean
}

export type TModules = readonly IModule[]

export interface IModuleCount {
  readonly appId: number
  readonly state: number
  readonly count: number
}

export type TModuleCounts = readonly IModuleCount[]

export interface IBaseAppModuleCountInfo {
  /** Aktivált modulok száma. */
  readonly activeModuleCount: number
  /** Inaktivált modulok száma. */
  readonly inactiveModuleCount: number
}

export type TProcessedModuleCountInfos = TReadonlyRecord<number, IBaseAppModuleCountInfo>

export interface IAppModuleCountInfo extends IBaseAppModuleCountInfo {
  /** Az adott app szériaszáma. */
  readonly serial: TSerial
  /** Összes modul. */
  readonly moduleCount: number
}

export type TAppModuleCountInfos = readonly IAppModuleCountInfo[]

export interface IGetModuleCountInfoResponse {
  readonly infos: TAppModuleCountInfos
  readonly summary: {
    readonly appCount: number
    readonly moduleCount: number
    readonly appsWithModules: number
    readonly activeModuleCount: number
    readonly appsWithNoModules: number
    readonly inactiveModuleCount: number
    readonly appsWithAllActiveModules: number
    readonly appsWithAllInactiveModules: number
  } | null
}

export interface IAppModuleDescriptionKeys {
  readonly moduleId: number
  readonly appId: number
  readonly descKey: string
  readonly titleKey: string
}

export type TAppModuleDescriptionKeys = readonly IAppModuleDescriptionKeys[]

export interface IModuleDescription {
  /** Az adott modul azonosítója. */
  readonly moduleId: number
  /** A befoglaló alkalmazás nyelvesítése. */
  readonly appTitles: TLanguageValue
  /** A modulhoz tartozó képek elérési útvonala. */
  readonly imagePath: string
  /** A modul, adott útvonalban elfoglalt indexe. */
  readonly moduleIndex: number
}

export type TModuleDescriptions = readonly IModuleDescription[]

// Used to be: Record<number, IModuleDescription>
export interface IGetModuleDescriptionsResponse {
  readonly moduleDescriptions: TModuleDescriptions
}

export interface ITrainingLabel {
  readonly id: number
  readonly labelType: TLabelType
}

export type TTrainingLabels = readonly ITrainingLabel[]

export interface ITrainingModul {
  readonly id: number
  readonly gradeDifficulties: readonly IBaseGradeDifficulty[]
  readonly labels: TTrainingLabels
}

export type TTrainingModules = readonly ITrainingModul[]

export interface IGetTrainingDataResponse {
  readonly modules: TTrainingModules
}

export interface ILabelInfo {
  /** Címke azonosító. */
  readonly id: number
  /** Címke megnevezés. */
  readonly name: string
  /** Az adott címkével felcímkézett modulok azonosítója.  */
  readonly moduleIds: readonly number[]
}

export type TLabelInfos = readonly ILabelInfo[]

/** TODO [MÁGUS]: ide írni kellene valami leírás! */
export interface ISubjectLabelInfo {
  readonly id: number
  readonly labels: TLabelInfos
}

export type TSubjectInfos = readonly ISubjectLabelInfo[]

export interface IGetSubjectLabelInfosResponse {
  readonly subjects: TSubjectInfos
}

export interface IGetDevAreasInfoResponse {
  readonly labels: TLabelInfos
}

interface IBaseRawLabel {
  readonly id: number
  readonly name: string
}

export type TBaseRawLabels = readonly IBaseRawLabel[]

export interface IBaseLabel {
  readonly name: string
  readonly labelType: TLabelType
  readonly parentLabelId: number | null
}

export type TBaseLabels = readonly IBaseLabel[]

export interface IBaseTitleData {
  readonly titles: TLanguageValue
}

export interface INewLabel extends IBaseLabel, IBaseTitleData {}

export interface ILabel extends IBaseLabel {
  readonly id: number
  readonly createdAt: string

  readonly parentLabelName: string | null
}

export type TLabels = readonly ILabel[]

/** Az adatbázisban található címkék kiegészítve nyelvesítéssel. */
export interface ITranslatedLabel extends ILabel, IBaseTitleData {}

export type TTranslatedLabels = readonly ITranslatedLabel[]

export type TLabelTranslationMap = Record<number, TLanguageValue>

export interface ILabelWithLevelOptionId extends ILabel {
  readonly labelType: TLabelType
  readonly levelOptionId: number
}

export type TLabelsWithLevelOptionId = readonly ILabelWithLevelOptionId[]

export interface ITranslatedLabelWithLevelOptionId extends ILabelWithLevelOptionId, TLanguageValue {}

export interface ILabelEditorLabel extends ITranslatedLabel {
  readonly labelTypeName: string
}

export type TPairedLabelsEditorLabels = readonly [ firstLabel: ILabelEditorLabel, secondLabel: ILabelEditorLabel ]

export type TLabelEditorLabels = readonly ILabelEditorLabel[]

export interface ILabelWithModuleId extends ITranslatedLabel {
  readonly moduleId: number
}

export interface ILabelWithModuleIdsNode {
  readonly moduleIds: number[]
  readonly titles: TLanguageValue
}

export type TLabelWithModuleIdsNodes = readonly ILabelWithModuleIdsNode[]

export interface ISubjectWithLabelNodes {
  readonly titles: TLanguageValue
  readonly labels: TLabelWithModuleIdsNodes
}

export type TSubjectsWithLabelNodes = readonly ISubjectWithLabelNodes[]

export interface IGetSubjectsWithLabelNodesResponse {
  readonly subjects: TSubjectsWithLabelNodes
}

export type TLabelsWithWithModuleId = readonly ILabelWithModuleId[]

// LevelOptions
export interface IAncientLevelOption {
  readonly optionKey: string
  readonly optionValue: string
}

export type TAncientLevelOptions = readonly IAncientLevelOption[]

export interface IAdvancedAncientLevelOption extends IAncientLevelOption {
  readonly serial: TSerial
  readonly transKey: string
}

export type TAdvancedAncientLevelOptions = readonly IAdvancedAncientLevelOption[]

interface IBaseLevelOption {
  readonly appId: number
  readonly optionKey: string
  readonly optionValue: string
}

interface ITypedBaseLevelOption extends IBaseLevelOption {
  readonly type: ELevelOptionType
}

export interface INewLevelOption extends ITypedBaseLevelOption {
  readonly custom?: string
  readonly transKey?: string
  readonly titleTransKey?: string
}

export type TNewLevelOptions = readonly INewLevelOption[]

export interface IGetLevelOption extends IBaseLevelOption {
  readonly custom: string | undefined
  readonly transKey: string | undefined
  readonly titleTransKey: string | undefined
}

export interface ILevelOption extends ITypedBaseLevelOption {
  readonly id: number
  readonly createdAt: string
  readonly custom: string | null
  readonly transKey: string | null
  readonly titleTransKey: string | null
  readonly isPrimary: ELevelOptionIsPrimary
}

export interface ILevelOptionWithModuleId extends ILevelOption, IModuleId {}

export type TLevelOptionsWithModuleId = readonly ILevelOptionWithModuleId[]

export type TLevelOptions = readonly ILevelOption[]

interface IActiveLevelOption extends ILevelOption {
  readonly transKey: string
}

export type TActiveLevelOptions = readonly IActiveLevelOption[]

export interface IGetActiveLevelOptionsBySerialResponse {
  readonly levelOptions: TActiveLevelOptions
}

// ModuleLabels
export interface IBaseModuleLabel extends IModuleId {
  readonly labelId: number
}

export type TBaseModuleLabels = readonly IBaseModuleLabel[]

export interface IBaseModuleLabelWithLabelType extends IBaseModuleLabel {
  readonly labelType: TLabelType
}

export type TBaseModuleLabelWithLabelTypess = readonly IBaseModuleLabelWithLabelType[]

export interface INewModuleLabel extends IBaseModuleLabel {}

export type TNewModuleLabels = readonly INewModuleLabel[]

export interface IModuleLabel extends IBaseModuleLabel {
  readonly createdAt: string
}

export type TModuleLabels = readonly IModuleLabel[]

export interface IBaseRouteModule extends IModuleId {
  readonly routeId: number
  readonly orderNum: number
}

export type TBaseRouteModules = readonly IBaseRouteModule[]

export interface IRouteModule extends IBaseRouteModule {
  readonly createdAt: string
}

export type TRouteModules = readonly IRouteModule[]

export interface IRouteModuleWithAppId extends IRouteModule {
  readonly appId: number
}

export type TRouteModulesWithAppIds = IRouteModuleWithAppId[]

export interface ISwapModulesRequest {
  readonly indexOne: number
  readonly indexTwo: number
}

// LevelOptionLabels
interface IBaseLevelOptionLabel {
  readonly labelId: number
  readonly levelOptionId: number
}

export interface IUpdatableLabels {
  readonly levelOptionLabels: unknown // TNewLevelOptionLabels
  readonly moduleLabels: TNewModuleLabels
  readonly gradeDifficulties: readonly IModuleIdWithGradeId[]
}

export interface ILevelOptionLabel extends IBaseLevelOptionLabel {
  readonly createdAt: string
}

export type TLevelOptionLabels = readonly ILevelOptionLabel[]

// ModuleLevelOptions
export interface IModuleLevelOption extends IModuleId {
  readonly levelOptionId: number
}

interface IModuleLevelOptionWithAppId extends IModuleLevelOption {
  readonly appId: number
}

interface IModuleLevelOptionWithLabelId extends IModuleLevelOption {
  readonly labelId: number
}

export type TModuleLevelOptionsWithLabelId = readonly IModuleLevelOptionWithLabelId[]

export type TModuleLevelOptionsWithAppId = readonly IModuleLevelOptionWithAppId[]

export type TModuleLevelOptions = readonly IModuleLevelOption[]

export interface ILevelOptionsData {
  readonly deletableLevelOptions: TLevelOptions
  readonly newLevelOptions: TRawLevelOptions
}

export interface ITranslatedLevelOption extends ILevelOption {
  readonly text?: string
  readonly title?: string
}

export type TTranslatedLevelOptions = readonly ITranslatedLevelOption[]

export interface ITranslatedLevelOptionWithLabels extends ITranslatedLevelOption {
  readonly labels: TTranslatedLabels
}

export type TTranslatedLevelOptionsWithLabels = readonly ITranslatedLevelOptionWithLabels[]

export interface ITranslatedLevelOptionWithLabelIds extends ITranslatedLevelOption {
  readonly labelIds: number[]
}
export type TTranslatedLevelOptionsWithLabelIds = readonly ITranslatedLevelOptionWithLabelIds[]

export interface IAppModule extends IModule {
  readonly levelOptions: TTranslatedLevelOptionsWithLabels
  readonly labels: TTranslatedLabels
  readonly gradeDifficulties: TTranslatedGradeDifficulties<IGradeDifficulty>
  readonly customTitle?: TLanguageValue
  readonly customDescription?: TLanguageValue
}

export type TAppModules = IAppModule[]

interface IBaseEditorDataModule extends IModule {
  readonly levelOptions: TTranslatedLevelOptionsWithLabels
}

interface IModuleEditorDataModule extends IBaseEditorDataModule {
  readonly labelIds: number[]
  readonly gradeDifficultyIds: readonly number[]
}

export interface IModuleFinderGetModuleIdsByRouteIdResponse {
  readonly moduleIds: readonly number[]
}

export interface IModuleFinderSearchRequest {
  readonly labelIds: number[]
  readonly gradeDifficultyIds: readonly number[]
  readonly keyword: string
}

export interface IGetSearchPreviewResponse {
  /** Hány olyan modul van, amely kielégíti az adott keresési feltételeket. */
  readonly moduleCount: number
  /** Címkeazonosítók, amelyek még kiválaszthatóak a jelenlegi keresési feltételt. */
  readonly possibleLabelIds: readonly number[]
  /** Évfolyamnehézség-azonosítók, amelyek még kiválaszthatóak a jelenlegi keresési feltételt. */
  readonly possibleGradeDifficultyIds: readonly number[]
}

export interface IModuleFinderPaginationSearchRequest extends IModuleFinderSearchRequest, IPaginationRequest {}

export interface IModuleFinderSearchResponse {
  readonly moduleIds: readonly number[]
  readonly selectedLabelIds?: readonly number[]
  readonly selectedGradeDifficultyIds?: readonly number[]
  readonly possibleLabelIds?: readonly number[]
  readonly possibleGradeDifficultyIds?: readonly number[]
}

export type TModuleEditorDataModules = readonly IModuleEditorDataModule[]

export interface IUpdateModuleRequest extends IUpdateModule {}

export interface IGetTranslatedLevelOptionsWithLabelsByAppIdRequest {
  readonly appId: number
}

export interface IGetModuleEditorDataRequest extends IGetTranslatedLevelOptionsWithLabelsByAppIdRequest {}

export interface IResetByAppIdRequest extends IGetTranslatedLevelOptionsWithLabelsByAppIdRequest {}

export interface IInsertNewModuleRequest extends INewRawModule {}

export type TOptionKeyLabelIds = Record<string, number[]>

export interface IGetTranslatedLevelOptionsWithLabelsByAppIdResponse {
  readonly appLabelIds: readonly number[]
  readonly appGradeDifficulties: TTranslatedGradeDifficulties<IGradeDifficulty>
  readonly optionKeyLabelIds: TOptionKeyLabelIds
  readonly levelOptions: TTranslatedLevelOptionsWithLabels
}

export interface IGetModuleEditorDataResponse extends IGetTranslatedLevelOptionsWithLabelsByAppIdResponse {
  readonly modules: TModuleEditorDataModules
}

export interface IAppModuleEditorData extends IGetModuleEditorDataResponse {
  readonly appId: number
}

export interface IGetTranslatedModulesByResponse {
  readonly modules: TAppModules
}

export interface IGetTranslatedModulesByRequest {
  readonly availabilityFlags?: number
}

export interface IGetTranslatedModulesPageByRequest extends IGetTranslatedModulesByRequest {
  readonly appId: number
  readonly actPage: number
  readonly itemsPerPage: number
}

export interface IGetTranslatedModulesByLevelOptionIdsRequest {
  readonly appId: number
  readonly availabilityFlags: number
  readonly levelOptionIds: readonly number[]
  readonly needSpecial?: boolean
}

export interface IGetTranslatedModulesToInactivateRequest extends IGetTranslatedModulesByLevelOptionIdsRequest {}

export type TGetTranslatedModulesByResponse = IPaginatorResponse<TAppModules>

export type TBaseSeparatedLevelOptions<T extends ITranslatedLevelOption = ITranslatedLevelOption> = Record<string, T[]>
export type TSeparatedLevelOptions = TBaseSeparatedLevelOptions<ITranslatedLevelOptionWithLabels>

export interface IGetTranslatedModulesByLevelOptionIdsResponse {
  readonly modules: TAppModules
  readonly levelOptions: TSeparatedLevelOptions
}

export interface IGetTranslatedModulesToInactivateResponse {
  readonly modules: TAppModules
}

export interface IGetTranslatedLevelOptionsByParams {
  readonly type?: ELevelOptionType
}

export interface IActivateModulesRequest {
  readonly ids: readonly number[]
}

export interface IInactivateModulesRequest extends IActivateModulesRequest {}

export interface IDeleteModuleByIdRequest {
  readonly id: number
}

export interface IGetModuleByIdRequest {
  readonly id: number
}

export interface IGetByIdModuleResponse {
  readonly module: IModule
}

export interface IInsertModuleRequest {
  readonly appId: number
  readonly baseModuleId: number
  readonly levelOptionIds: number[]
}

export interface IIsExistModuleRequest extends IInsertModuleRequest {}

export interface IIsExistModuleResponse {
  readonly isExist: boolean
}

export interface IActivateByAppIdModulesRequest {
  readonly appId: number
}

export interface IHasInactiveModulesRequest {
  readonly appId: number
}

export interface IHasInactiveModulesResponse {
  readonly inactiveModuleCount: number
  readonly hasSpecialLevelOptions: boolean
}

export interface IInsertModuleResponse {
  readonly id: number
}

export interface IAdvLevelOption extends ILevelOption {
  readonly canBePrimary: boolean
}

export type TAdvLevelOptions = readonly IAdvLevelOption[]

export interface IGetLevelOptionsByAppIdResponse {
  readonly levelOptions: TAdvLevelOptions
}

export interface IUpdateLevelOptionLabels {
  readonly levelOptionId: number
  readonly labelIds: number[]
}

export type TUpdateLevelOptionLabels = readonly IUpdateLevelOptionLabels[]

export interface ILabelingRequest {
  /** A címkék azonosítói. */
  readonly labelIds: readonly number[]
  /** Évfolyamnehézség azonosítók. */
  readonly gradeDifficultyIds: readonly number[]

  /** OptionKey, ha van. */
  readonly optionKey: string | null
  /** Modulazonosító, ha van. */
  readonly moduleId: number | null
  /** Szintopció azonosító, ha van. */
  readonly levelOptionId: number | null
}

export interface IGetTranslatedLabelsByTypeResponse {
  readonly labels: TTranslatedLabels
}

export interface IGetLabelThemes {
  readonly labels: TTranslatedLabels
}

interface ILabelWithAppIds extends ITranslatedLabel {
  readonly appIds: readonly number[]
}

export type TLabelsWithAppIds = readonly ILabelWithAppIds[]

export interface IGetLabelThemesWithAppIds {
  readonly labels: TLabelsWithAppIds
}

export interface IGetLabelByIdResponse {
  readonly label: ITranslatedLabel
  readonly possibleParentLabels: TLabels
}

export interface IInsertLabelRequest extends INewLabel {}

export interface IUpdateLabelRequest extends IInsertLabelRequest {}

export interface IGrouppedLabelType {
  readonly labelType: TLabelType
  readonly titles: TLanguageValue
  readonly labels: TMutable<TTranslatedLabels>
}

export type TGrouppedLabelTypes = readonly IGrouppedLabelType[]

export interface IGetLabelsEditorDataResponse {
  readonly labels: TGrouppedLabelTypes
  readonly gradeDifficulties: TTranslatedGradeDifficulties<IGradeDifficulty>
}

/** Ez az interface az Ügyesedni appokhoz kell, hogy szériaszám és module azonosító alapján elkérjük a hozzátartozó modult. */
export interface IGetLevelOptionsBySerialAndModuleIdRequest extends IModuleId {
  readonly serial: TSerial
}

export interface IGetLevelOptionsBySerialAndModuleIdResponse {
  readonly levelOptions: TLevelOptions
}

export interface IGetLevelOptionByIdRequest {
  readonly id: number
}

export interface IGetLevelOptionByIdResponse {
  readonly levelOption: ILevelOption
}

export interface IUpdateLevelOption {
  readonly id: number
  readonly key: string
  readonly value: string
  readonly custom: string

}

export interface IUpdateLevelOptionRequest extends IUpdateLevelOption {
  readonly isPrimary: ELevelOptionIsPrimary
}

export interface IRawExcelModuleLabel {
  readonly appName: string
  readonly module: string
  readonly label: string
  readonly labelType: string
}

export type TRawExcelModuleLabels = readonly IRawExcelModuleLabel[]

export interface ISendModuleLabelsRequest {
  readonly moduleLabels: TRawExcelModuleLabels
}

export interface IRawExcelModuleLabelWithIds extends IRawExcelModuleLabel {
  readonly appId: number
  readonly labelId: number
  readonly serial: string
}

export type TRawExcelModuleLabelWithAppIds = readonly IRawExcelModuleLabelWithIds[]

export interface IDefinedRawExcelModuleLabel extends IRawExcelModuleLabelWithIds, IModuleId {}

export type TDefinedRawExcelModuleLabels = IDefinedRawExcelModuleLabel[]

export interface IEditorRouteModule {
  readonly id: number
  readonly appName: string
  readonly serial: string
  readonly isActive: boolean
  readonly levelOptions: TTranslatedLevelOptions
  readonly labels: TTranslatedLabels
  readonly gradeDifficulties: TGradeDifficulties
}

export type TEditorRouteModules = readonly IEditorRouteModule[]

export interface IGetRouteWithModulesByIdResponse extends IRoute {
  readonly modules: TEditorRouteModules
  readonly canSkip?: boolean
  readonly themes?: readonly TThemeLabelName[]
}

export interface IGetTranslatedModulesByModuleIdsRequest {
  readonly moduleIds: number
}

export interface IGetTranslatedModulesByModuleIdsResponse {
  readonly modules: TEditorRouteModules
}

export interface IGetModulesBySearchGetPageRequest extends IRoutesGetPageRequest {
  readonly labelIds?: readonly number[]
}

export interface IGetModuleIdByAncientLevelOptionsRequest {
  readonly levelOptions: TAdvancedAncientLevelOptions
}

export interface IGetModuleIdByAncientLevelOptionsResponse {
  readonly moduleId: number
}

export interface ISearchAppModule extends IModule {
  readonly appName: string
  readonly serial: string
  readonly labels: TTranslatedLabels
  readonly gradeDifficulties: TTranslatedGradeDifficulties<IModuleGradeDifficulty>
  readonly levelOptions: TTranslatedLevelOptions
}

export type TSearchAppModules = readonly ISearchAppModule[]

export interface IGetModulesBySearchGetPageResponse extends IPaginatorResponse<TSearchAppModules> {
  readonly labels: TTranslatedLabels
}

export interface IGetSearchModuleByIdRequest {
  readonly id: number
}

export interface IGetSearchModuleByIdResponse {
  readonly module: ISearchAppModule
}

// ROUTE LABELING

export interface ISetAppKeyValuesBySerialRequest extends IBaseSetAppKeyValuesBySerialRequest {
  readonly key: string
}

export interface ILevelCheckerRouteFilter {
  /** Tantárgyak, fordítással együtt. */
  readonly subjects: TTranslatedSubjects
  /** Évfolyam-időszakok tömbje, a fordításokkal együtt. */
  readonly gradePeriods: TTranslatedGradePeriods<IGradePeriod>
  /** A lekérdezés állapotában a legközelebbi dátumpont, amelyhez tartozik szintefelmérő. */
  readonly closestPeriod: TYearPeriod
}

export interface IBaseModuleImage {
  readonly imageKey: string
  readonly appId: number
}

export type TBaseModuleImages = readonly IBaseModuleImage[]

export interface INewModuleImage extends IBaseModuleImage {
  readonly path: string
}

export interface IModuleImage extends INewModuleImage {
  readonly id: number
  readonly createdAt: string
}

export type TModuleImages = readonly IModuleImage[]

export interface IAppDescriptionKeys {
  readonly appId: number
  readonly descKey: string
  readonly titleKey: string
}

export type TAppDescriptionKeys = readonly IAppDescriptionKeys[]

interface IBaseAppDescription {
  readonly key: string
}

interface IAppDescription extends IBaseAppDescription {
  readonly titles?: TLanguageValue
}

export interface IModuleAppDescription {
  readonly desc: IAppDescription
  readonly title: IAppDescription
}

export type TModuleAppDescriptions = readonly IModuleAppDescription[]

interface IUpdateAppDescription extends IBaseAppDescription {
  readonly titles: TLanguageValue
}

export interface IUpdateAppDescriptionRequest {
  readonly desc: IUpdateAppDescription
  readonly title: IUpdateAppDescription
  readonly serial: string
}

export interface IGetAppDescriptionsRequest {
  readonly appId: number
  readonly serial: string
}

export interface IGetModuleAppDescription extends IModuleAppDescription {
  readonly moduleImage?: IModuleImage
}

export type TGetModuleAppDescriptions = readonly IGetModuleAppDescription[]

export interface IGetAppDescriptionsResponse {
  readonly descriptions: TGetModuleAppDescriptions
}

export interface IGetAppDescriptionByModuleIdResponse {
  readonly description: IGetModuleAppDescription
}

export type TTranslatedLabelsWithLevelOptionId = TSetTitleResult<ILabelWithLevelOptionId>

export enum EAppModuleDataStatus {
  True   = 0,
  False  = 1,
  Partly = 2
}

interface IAppDescriptionsApp extends IApp {
  readonly primaryOptionKeys: readonly string[]
  readonly hasAppDescriptions: EAppModuleDataStatus
  readonly hasAppModuleImages: EAppModuleDataStatus
}

export type TAppDescriptionsApps = readonly IAppDescriptionsApp[]

export interface IInsertUserRouteRequest {
  readonly name: string
  readonly moduleIds: readonly (number | null)[]
}

export interface IUpdateUserRouteRequest extends IInsertUserRouteRequest {}

export interface IInsertUserRouteResponse {
  readonly routeId: number
}

export interface IGetUserRoutesResponse extends IPaginationResponse<TRoutesWithModuleCounts> {}

export interface IDeleteUserRoutesByIdsRequest {
  readonly routeIds: readonly number[]
}

export interface IModuleFinderGetLabelsResponse {
  readonly moduleCount: number
  readonly labels: TGrouppedLabelTypes
  readonly gradeDifficulties: TTranslatedGradeDifficulties<IGradeDifficulty>
}

export interface IModuleImagesInsertBase64ImageRequest {
  readonly appId: number
  readonly imageKey: string
  readonly base64image: string
}

export interface IGetPossibleRouteModulesRequest {
  readonly moduleIds: readonly number[]
  readonly count: number
  readonly isStrict?: boolean
  readonly except?: readonly number[]
}

// ----------------------------
//
// [PATHBUILDER]
//
// Igaz, hogy ez a négy interface jelenleg ugyanazt tudja, de én nem közösíteném, mert sose lehet tudni, hogy később nem lesznek eltérések.
//

export interface IPossibleModule {
  /** A modul azonosítója. */
  readonly moduleId: number
  /** A modulhoz tartozó kép elérési útja. */
  readonly imagePath?: string
  /** Langeditben tárolt, a képhez tartozó szöveg. */
  readonly moduleName: string
  /** A modult tartalmazó app szériaszáma. */
  readonly serial: string
  /** A modult tartalmazó app azonosítója. */
  readonly appId: number
}

export type TPossibleModules = readonly IPossibleModule[]

export interface IGetModuleIdSerialPairsByRouteIdResponse {
  readonly modules: TPossibleModules
}

/** App azonosító alapján visszaadott modulok, lapozóval. */
export interface IGetModulesByAppIdResponse extends IPaginationResponse<TPossibleModules> {}

/** A felhasználó kedvenc moduljai, lapozóval. */
export interface IGetUserFavouriteModulesReponse extends IPaginationResponse<TPossibleModules> {}

/** Cimkék és egyéb keresési feltételek alapján visszaadott modulok, lapozóval.  */
export interface IGetModulesBySearchResponse extends IPaginationResponse<TPossibleModules> {}

/** Modulazonosító lista alapján visszaadott modulok, lapozóval. */
export interface IGetModulesByModuleIdsResponse extends IPaginationResponse<TPossibleModules> {}

/**
 * Egy megadott felhasználóhoz az AI által generált modulok listája.
 * Jelenleg fixen 10-et generálunk, de később ez változhat, ezért már most megy a lapozó is.
 */
export interface IGetModulesByUserIdentifier extends IPaginationResponse<TPossibleModules> {}

export interface IGetPossibleModuleBySerialAndLevelOptionsRequest {
  readonly optionKeys: string[]
  readonly optionValues: (string | number)[]
}

export interface IGetPossibleModuleBySerialAndLevelOptionsResponse {
  readonly module: IPossibleModule
}

export interface IGetDigestById extends IRoute {
  readonly modules: TPossibleModules
}

export interface IRouteWithModuleIds extends IRoute {
  readonly moduleIds: readonly number[]
}

export type TRoutesWithModuleIds = readonly IRouteWithModuleIds[]

export interface IRouteWithModuleIdsAndGradePeriods extends IRouteWithModuleIds {
  readonly gradePeriods: TGradePeriods
}

export type TRoutesWithModuleIdsAndGradePeriods = readonly IRouteWithModuleIdsAndGradePeriods[]

// ----------------------------

export interface IGetLabelTypesByModuleIdsRequest {
  readonly moduleIds: readonly number[]
}

export interface ILabelTypesByModuleId {
  readonly moduleId: number
  readonly labelType: TLabelType
  readonly labelName: string
  readonly labelId: number
}

export type TLabelTypesByModuleIds = readonly ILabelTypesByModuleId[]

export interface ITranslatedLabelTypesModuleId extends ILabelTypesByModuleId {
  readonly labelTypeTitles: TLanguageValue
  readonly labelTitles: TLanguageValue
}

export type TTranslatedLabelTypesByModuleIds = readonly ITranslatedLabelTypesModuleId[]

export interface IGetLabelTypesByModuleIdsResponse {
  readonly labelTypes: TTranslatedLabelTypesByModuleIds
}

export type TModulePercent = [ moduleId: number, matchPercentage: number ]

export type TModulePercents = TModulePercent[]

export interface ICreateRouteByThemeAndGradeRequest {
  readonly themeLabelId: number
  readonly grade: TChildrenGrade
}

export interface ICreateRouteByThemeAndGradeResponse {
  readonly routeId: number
}

export interface ILabelWithModuleIds {
  readonly id: number
  readonly labelType: TLabelType
  readonly parentLabelId: number | null
  readonly moduleIds: number[]
}

export type TLabelsWithModuleIds = readonly ILabelWithModuleIds[]

export interface ITranslatedLabelWithModuleIds extends ILabelWithModuleIds {
  readonly titles: TLanguageValue
}

export type TTranslatedLabelWithModuleIds = readonly ITranslatedLabelWithModuleIds[]

export interface IGetLabelsWithModuleIdsResponse {
  readonly labels: TTranslatedLabelWithModuleIds
}

export interface IGetModulesByLabelPairsResponse {
  readonly labelsWithModules: TLabelsWithModuleIds
}

export interface IGetLevelCheckersResponse {
  readonly routes: TRoutesWithModuleIdsAndGradePeriods
}

export interface ILabelByRouteId {
  readonly routeId: number
  readonly moduleId: number
  readonly labelId: number
}

export type TLabelsByRouteIds = readonly ILabelByRouteId[]

export type TTranslatedModuleLabels = readonly (INewModuleLabel & { readonly titles: TLanguageValue })[]

export interface ILevelCheckerLabelInfo {
  /** Útvonalazonosító. */
  readonly routeId: number
  /** A tantárgyra vonatkozó infók.. */
  readonly translatedSubject: ITranslatedSubject
  /** Az hozzácsatolt évfolyam-nehézség párosok. */
  readonly gradePeriods: TBaseGradePeriods
  /** Az összes létező modulcímke párosítás. */
  readonly moduleLabels: TNewModuleLabels
  /** Az adott útvonalban található modulok azonosítói. */
  readonly moduleIds: readonly number[]
}

interface IAppLabelId {
  readonly appId: number
  readonly labelId: number
}

export type TAppLabelIds = readonly IAppLabelId[]

export interface IBaseFavouriteModule {
  readonly moduleId: number
  readonly userId: string
}

export interface IFavouriteModule extends IBaseFavouriteModule {
  readonly createdAt: string
}

export type TFavouriteModules = readonly IFavouriteModule[]

export type TLevelCheckerLabelInfos = readonly ILevelCheckerLabelInfo[]

export interface IGetLevelCheckerInfosRequest {
  readonly routeIds: readonly number[]
}

export interface IGetLevelCheckerInfosResponse {
  /** A szintfelmérőkhöz tartozó releváns információk. */
  readonly infos: TLevelCheckerLabelInfos
  /** A hozzácsatolt címkék fordításai. */
  readonly labelTitles: TLabelTranslationMap
}

export interface IDifficultyPair {
  readonly moduleId: number
  readonly grade: string
  readonly difficulty: string
}

export type TDifficultyPairs = readonly IDifficultyPair[]

export interface IModuleInfo {
  readonly moduleId: number
  readonly difficulties: TModuleGradeDifficulties
  readonly subjects: readonly number[]
  readonly devAreas: readonly number[]
}

export type TModuleInfos = readonly IModuleInfo[]

export interface IGetModuleInfosRequest {
  readonly moduleIds: readonly number[]
}

export interface IGetModuleInfosResponse {
  readonly infos: TModuleInfos
  readonly labelTitles: TLabelTranslationMap
}

export interface IIsFavouriteRequest {
  readonly moduleId: number
  readonly session: string
}

export interface IToggleFavouriteRequest extends IIsFavouriteRequest {}

/**
 * Ez mondja meg, hogy az adott modul esetén már kedvencnek van-e jelölve, nincs, vagy egyáltalán jelölhet-e kedvencnek.
 * Bárminemű változtatás esetén a összes appot újra kell releaselni!
 */
export enum EIsFavourite {
  True            = 0,
  False           = 1,
  HasNoPermission = 2
}

export interface IIsFavouriteResponse {
  readonly isFavourite: EIsFavourite
}

export interface IToggleFavouriteResponse extends IIsFavouriteResponse {}

export type {
  TApps, TSerial, TLanguageValue, TSetTitleResult, TThemeLabelName,
  TRawLevelOptions, IPaginatorResponse, TAvailableLanguage, IBaseRawLevelOption
}

export type {
  IRawLevelOption, TLevelOptionValue, TLevelCombinations, ILevelOptionCombinations
} from '@apps/testers/levelCombinationsCollector/definitions'

export type { IApp } from '../appService/definitions'

export type {
  TLanguageParts,
  ISetPartsRequest,
  IAppKeyWithAppId,
  TAppKeyWithAppIds,
  IAppKeyWithSerial,
  TTranslatedAppKeys,
  TAppKeysWithSerials,
  IDeleteAppKeysModuleServiceRequest,
  IGetAppKeysValuesBySerialModuleServiceRequest,
  IGetAppKeysValuesBySerialModuleServiceResponse
} from '@langedit/backend/definitions'

export type { ICustomError } from '@common/backend/definitions'

export { THEME_LABEL_NAMES } from '@ugyesedni/definitions'

export { EDU_GAME_TYPES, EDU_BACKGROUND_TYPES } from '@apps/definitions'

export interface IBaseThemeLabelCount {
  readonly count: number
  readonly themeName: TThemeLabelName
}

export interface IThemeLabelCount extends IBaseThemeLabelCount {
  readonly moduleId: number
}

export type TThemeLabelCounts = readonly IThemeLabelCount[]

export interface IRouteThemeLabelCount extends IBaseThemeLabelCount {
  readonly routeId: number
}

export type TRouteThemeLabelCounts = readonly IRouteThemeLabelCount[]

export interface IResource {
  readonly id: number
}

export type TResources = readonly IResource[]

export interface IResourceIdWithLabeLId {
  readonly resourceId: number
  readonly labelId: number
}

export type TResourceIdsWithLabelIds = readonly IResourceIdWithLabeLId[]

export const TRANSLATION_TYPES = [
  'category', 'childrenGrade', 'devArea', 'yearPeriod',
  'moduleLabel', 'moduleLabelType', 'subject', 'difficulty'
] as const

export type TTranslation = typeof TRANSLATION_TYPES[number]

// Kulcsok lehetnek:
export type TPartTreeKey = (
  /** Címkék azonosítói. */
  number |
  /** Tantárgyak konkrét típusa. */
  TSubject |
  /** Címketípus. */
  TLabelType |
  /** Évfolyam. */
  TChildrenGrade |
  /** Időszak. */
  TYearPeriod |
  /** Nehézségek.  */
  TDifficulty
)

// TReadonlyRecord<TPartTreeKey, TLanguageValue>
export type TPartTree = {
  readonly [K in TPartTreeKey]?: TLanguageValue
}

// TReadonlyRecord<TTranslation, TPartTree>
export type TPartsTree = {
  readonly [K in TTranslation]?: TPartTree
}

/** Címkefa. */
export interface IBaseLabelNode {
  /** A címke azonosítója. */
  readonly id: number
  /** A címke szülőjének azonosítója. */
  readonly parentLabelId: number | null
}

export type TLabelNode<T extends IBaseLabelNode = IBaseLabelNode> = T & {
  children: TLabelNode <T>[]
}

export type TLabelNodes<T extends IBaseLabelNode> = readonly TLabelNode<T>[]

export interface ILabelTree<T extends IBaseLabelNode> {
  readonly children: TLabelNodes<T>
}

export interface IGetLabelTreeQuery {
  readonly labelTypes: TLabelType[]
}

export interface IGetLabelTreeResponse {
  readonly tree: ILabelTree<ITranslatedLabel>
}

export interface IGetModuleableAppIdsResponse {
  readonly appIds: readonly number[]
}

/** Teljes appok keresőjéhez tartozó típusok, interfészek. */
export interface IWholeApp {
  /** Az app azonosítója. */
  readonly id: number
  /** A játék neve. */
  readonly name: string
  /** A játék szériaszáma. */
  readonly serial: string
  /** A játék a felhasználó számára elindítható, elérhető? */
  readonly isUnlocked: boolean
}

export type TWholeApps = readonly IWholeApp[]

export interface ISearchWholeAppsResponse {
  readonly wholeApps: TWholeApps
}

/** Pedagógiai kategória (ex: régi kategóriák fül adminon). */
export interface IPedagogicalCategory {
  readonly id: number
  readonly titles: TLanguageValue
}

export type TPedagogicalCategories = IPedagogicalCategory[]

export interface IWholeAppFinderGradeDifficulty {
  /** Az évfolyam. */
  readonly grade: TChildrenGrade
  /** A nehezség, amely lehet NULL is, hiszen ... (fenti magyarázat). */
  readonly difficulty: TDifficulty | null
  /** Nyelvesítés. */
  readonly titles: TLanguageValue
}

export type TWholeAppFinderGradeDifficulties = readonly IWholeAppFinderGradeDifficulty[]

export interface IWholeAppsFinderFilter {
  /** Témák, amelyekre lehet szűrni. */
  readonly themes: TTranslatedLabels
  /** Tantárgyak, amelyekre lehet szűrni. */
  readonly subjects: TTranslatedLabels
  /** Pedagógiai ketegóriák, amelyekre lehet szűrni. */
  readonly pedagogicalCategories: TPedagogicalCategories
  /** Évfolyam – és olykor nehézségi szint –, amelyre lehet szűrni. */
  readonly gradeDifficulties: TWholeAppFinderGradeDifficulties
}

export interface IWholeAppsFinderInitResponse {
  readonly apps: TWholeApps
  readonly filters: IWholeAppsFinderFilter
}

export interface IWholeAppsOnlyWithModulesRequest {
  readonly onlyAppsWithModules?: boolean
}

export interface IWholeAppsFinderRequest extends IWholeAppsOnlyWithModulesRequest {
  /** A kiválasztott címkék azonosítói: egy tantárgy és/vagy egy téma. */
  readonly labelIds?: readonly number[]
  /** A kiválasztott évfolyam, ha egyáltalán van rá szűrés. */
  readonly grade?: TChildrenGrade | undefined
  /** A kiválasztott nehézség, ha egyáltalán van rá szűrés. */
  readonly difficulty?: TDifficulty
  /** A kiválasztott pedagógiai kategória, ha egyáltalán van rá szűrés. */
  readonly pedagogicalCategoryId?: number
}

export interface IGenerateRecommendableModulesResponse {
  /** Az AI által generált modulok tömbje. */
  readonly modules: TPossibleModules
  /** A gyerek adatai, akinek generáltattuk a modulokat. */
  readonly child: IChild
}

export interface ISaveAndSendGeneratedRouteRequest {
  readonly moduleIds: readonly number[]
}

export interface IMinimalModule {
  readonly id: number
  readonly appId: number
}

export type TMinimalModules = readonly IMinimalModule[]

export interface IExperienceSpaceModuleInfo {
  readonly moduleId: number
  readonly state: string // TODO: típus!!!
  readonly appSerial: string
}

export type TExperienceSpaceModuleInfos = readonly IExperienceSpaceModuleInfo[]

export interface IExperienceSpaceInitResponse {
  /** Az adott útvonalban szereplő témák tömbje. */
  readonly themes: readonly string[]
  /** Lehet-e modulokat kihagyni az útvonalban. */
  readonly canSkip: boolean
  /** Az útvonalban lévő modulok információi. */
  readonly modules: readonly IExperienceSpaceModuleInfo[]
}

export interface IAreRoutesSendableParams {
  /** A megvizsgálandó útvonalazonsítók. */
  readonly routeIds: readonly number[]
  /** Az adott platform, amin ki szeretnénk küldeni. */
  readonly platform: TAvailablePlatform
}

export interface IHasGeneratedRouteResponse {
  /** Megmondja, hogy egy adott felhasználó generált-e már AI-val útvonalat. */
  readonly hasGenerated: boolean
}
