import { baseGlobals } from '@root/typescript/baseGlobals'

import ajax from '@common/ajax/v1/ajax'
import type { TErrorCallback, TSuccessCallback } from '@common/ajax/definitions'

import CookieHandler from '../../CookieHandler'

import styles from './Form.css'

interface IProps {
  readonly action: string
  readonly success: TSuccessCallback
  readonly error: TErrorCallback
  readonly children?: HTMLElement[]
}

/**
 * Közösített form komponens, ami ajax-ot küld a hagyományos form submit helyett.
 * @param props - JSX props {@link IProps}.
 */
export default function Form (props: IProps): HTMLFormElement {
  const form: HTMLFormElement = (
    <form className={ styles.form }>{ props.children }</form>
  )

  form.addEventListener('submit', (e: Event) => {
    e.preventDefault()

    // Csak bejelentkezéskor állítjuk be a source-ot.
    CookieHandler.setSourceCookie()

    // Legyen hülyebiztos az url hozzáadás.
    const url = `${ baseGlobals.isDev ? 'http://localhost:3100' : '' }${ props.action.startsWith('/') ? props.action : `/${ props.action }` }`

    ajax(
      url,
      {
        withCredentials: true,
        // A form input elemei alapján majd összeszedi az adatot.
        data: form,
        success: props.success,
        error: props.error
      }
    )
  })

  return form
}
