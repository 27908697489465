import { is } from '@common/is/is'
import { Object } from '@common/Object/Object'

import Category from './components/Category/Category'
import type { TPlaygroundData } from './definitions'

/**
 * Az összes app renderelését végző függvény.
 * @param data         - Az appok listája, amit renderelni fog a függvény.
 * @param attachedApps - Az elérhető appok ID listája.
 * @param isLoggedIn   - Be van-e jelentkezve a user.
 */
export default function renderProdApps (
  data: TPlaygroundData,
  attachedApps: number[],
  isLoggedIn: boolean
): void {
  const parent = document.querySelector('#apps-container')

  if (!is.instanceOf(parent, HTMLElement)) {
    return
  }

  const categories = Object.getKeys(data).map((name) => {
    // Ha üres az attachedApps tömb, akkor az két dolgot jelent:
    // 1) nincs bejelentkezett user, ilyenkor mindent megjelenítünk,
    // 2) van bejelentkezett user DE nincs egy apja sem, így semmit nem kell megjeleníteni.
    const apps = is.empty(attachedApps)
      ? isLoggedIn
        ? []
        : data[name]
      : data[name].filter((app) => attachedApps.includes(app.id))

    if (!is.empty(apps)) {
      return <Category name={ name } apps={ apps } />
    }

    return null
  })

  // Töröljük az jelenlegi appokat.
  parent.innerHTML = ''

  parent.appendChild(
    <Fragment>
      { categories }
    </Fragment>
  )
}
