import type ContentScale from '@common/ContentScale/ContentScale'

import type { TRestrictions } from '@common/RestrictionHandler/definitions'

import type { TLanguage, TLanguages } from './translator/definitions'

import type { ENGINE, SUBJECT, NARRATION, DEV_PHASE, DEMO_STATUS, MUSIC_TYPES, NO_NARRATION } from './consts'

export type TElemRect = ReturnType<typeof ContentScale.getElementOffset>

export type TEngine = keyof typeof ENGINE
export type TNarration = keyof typeof NARRATION
type TNoNarration = keyof typeof NO_NARRATION
export type TDemoStatus = keyof typeof DEMO_STATUS
export type TDevPhase = keyof typeof DEV_PHASE
export type TSubject = keyof typeof SUBJECT

export type TCustomElementPrefix = 'sni' | 'edu' | 'idoc' | 'earl'

export enum ENavigateEvent {
  Game     = 'Navigate:Game',
  Main     = 'Navigate:Main',
  Level    = 'Navigate:Level',
  Info     = 'Navigate:Info',
  Settings = 'Navigate:Settings'
}

export const CUSTOM_SCENES = [ 'settings', 'info' ] as const

export type TCustomScene = typeof CUSTOM_SCENES[number]

export const SCENES = [
  'game', 'main', 'level', ...CUSTOM_SCENES
] as const

export type TScene = typeof SCENES[number]

export interface IGameProps {
  readonly maxRounds: number
  readonly isStrictGamePlay: boolean
  readonly solutionCountPerRound: number
}

export interface IFavouriteModuleProps {
  readonly isFavourite: boolean
  readonly moduleId: number
}

export enum EGameEvent {
  Restart            = 'Game:Restart',
  ShowStat           = 'Game:ShowStat',
  ShowUserGuideModal = 'Game:ShowUserGuideModal'
}

// V2
export const enum EPageManagerEvent {
  Game   = 'PageManager:Game',
  Main   = 'PageManager:Main',
  Level  = 'PageManager:Level',
  Custom = 'PageManager:Custom'
}

// EDU
export const EDU_GAME_TYPES = [
  'two-elem-operation', 'matrix', 'parity', 'split', 'fraction',
  'sort', 'ruler', 'local-value', 'sum-diff', 'sum-sub-step'
] as const

export const SFX_THEMES = [
  'default', 'crystal'
] as const

export type TSfxTheme = typeof SFX_THEMES[number]

export const EDU_BACKGROUND_TYPES = [ 'table', 'booklet' ] as const

export type TEduBackground = typeof EDU_BACKGROUND_TYPES[number]

export const REQUIRED_EDU_MODULAR_OPTION_KEYS = [ 'background', 'game-type' ] as const

export enum EEduGamePageEvent {
  SendElement = 'Game:SendElement'
}

export enum EEduGameEvent {
  EndRound    = 'Game:EndRound',
  StartGame   = 'Game:StartGame',
  StartRound  = 'Game:StartRound'
}

export enum EResultsEvent {
  Show        = 'Game:Results:Show',
  ShowActual  = 'Game:Results:Actual',
  ShowSummary = 'Game:Results:Summary'
}

// GAME OPTIONS
export enum EUserGuideButtonEvent {
  Enable  = 'UserGuideButton:Enable',
  Disable = 'UserGuideButton:Disable'
}

export enum EGameOptionsEvent {
  Show     = 'GameOptions:Show',
  Hide     = 'GameOptions:Hide',
  Rerender = 'GameOptions:Rerender'
}

// MAIN MENU
export enum EMainMenuEvent {
  Close = 'MainMenu:Close'
}

// IDOCTUM
export const IDOCTUM_PRE_TRANS_KEY = 'structure'

export type { TLanguage, TLanguages }

export interface ISoundImprint {
  name: string
  link?: string
}

export interface IUiTranslate {
  readonly imprintModal?: TLanguage
  readonly methodologyModal?: TLanguage
  readonly generalInformationModal?: TLanguage
  readonly settingsModal?: TLanguage
}

export interface IImprint {
  code: readonly string[]
  gfx: readonly string[]
  content: readonly string[]
  manager: readonly string[]
  narration: readonly string[]
  sound?: ISoundImprint[]
}

type TSingleDigitNumber = 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9

type TTreeDigitNumber = `${ TSingleDigitNumber }${ TSingleDigitNumber }${ TSingleDigitNumber }`
export type TSerial = `BD-TO0${ TTreeDigitNumber }`

export type TMusic = typeof MUSIC_TYPES[number]

interface IMainMusicProps {
  readonly type: TMusic
  readonly volume?: number
}

interface IGameMusicPropsVolume {
  readonly volume: number
}

interface IGameMusicPropsType {
  readonly type: TMusic
}

interface IGameMusicProps extends IGameMusicPropsVolume, IGameMusicPropsType {}

export type TGameMusicProps = IGameMusicPropsVolume | IGameMusicPropsType | IGameMusicProps | false

export interface IMusic {
  readonly main: IMainMusicProps
  readonly game?: TGameMusicProps
}

export interface IConfig {
  readonly app: string
  /** Az app szériaszáma. */
  readonly serial: TSerial
  readonly subject?: TSubject

  /** Melyik játékmotorunkkal készült? */
  readonly engine: TEngine
  readonly sfxTheme?: TSfxTheme
  readonly server: string
  readonly demoStatus: TDemoStatus

  /** Az app fejlesztési állapota. */
  readonly devPhase: TDevPhase
  readonly background: string
  readonly progressColor: string
  readonly version: number
  readonly googlePlayVersion: string

  /** Az appon belüli támogatott nyelvek. */
  readonly langs: TLanguages
  readonly baseFonts: string[]
  readonly fonts: string[]
  readonly text: string
  readonly music: IMusic | false

  /** Impresszum. */
  readonly imprint: IImprint
  readonly googlePlayLink: string
  readonly appStoreLink: string
  readonly purchaseLink: string
  readonly desktopExeLink: string
  readonly narration: TNarration[] | TNoNarration
  /* eslint-disable @typescript-eslint/naming-convention */
  activator: boolean
  useBuiltInFonts: boolean
  readonly legacy: boolean
  /* eslint-enable @typescript-eslint/naming-convention */
  readonly uiTranslate?: IUiTranslate
  readonly restrictions?: TRestrictions

  // Szükség van loading képernyőre a gameScene-re navigáláskor?
  readonly needLoadingScreen?: boolean
}

export type { IEvent } from '@common/EventHandler/EventHandler'

type TPostMessageCommand = (
  /** A beágyazó (platform) iframe-be rakta az appot. */
  'embedded' |
  /** Az app szól a beágyazónak, hogy be szeretnénk zárni. */
  'close'
)

// Olyan játékok irányítórendszere, ahol keybourd input irányítás van / lehetséges.
/**
 * Ha valamelyik platform (ugyesedni.hu, speclearn.eu, buildelt platform) beágyazza az appot,
 * akkor küld neki egy postMessage-et és ennek az interface-nek kell megfelelnie a küldött adatnak.
 *
 * Valamint az app tud visszafelé is kommunikálni a beágyazó felé.
 */
export interface IPostMessageData {
  readonly cmd: TPostMessageCommand
}

// Amennyiben játékspecifikusan extra gombok szükségesek, a játékban bővíthető.

const UP_KEYS    = [ 'ArrowUp',    'w' ] as const
const DOWN_KEYS  = [ 'ArrowDown',  's' ] as const
const LEFT_KEYS  = [ 'ArrowLeft',  'a' ] as const
const RIGHT_KEYS = [ 'ArrowRight', 'd' ] as const

export const CONTROLLER_KEYS = [
  ...UP_KEYS,
  ...DOWN_KEYS,
  ...LEFT_KEYS,
  ...RIGHT_KEYS
] as const

export const KEYS = {
  up:    UP_KEYS,
  down:  DOWN_KEYS,
  left:  LEFT_KEYS,
  right: RIGHT_KEYS
}

export type { TRestrictions }

export { EStatusRoute } from '../services/moduleService/definitions'
export { EPreloaderEvent } from '../appShell/preloader/definitions'

export type { ILevelOptionsData } from '../services/moduleService/definitions'

export { SOURCES, MODULAR_ATTR, MODULE_ID_ATTR, MODULE_TYPE_ATTR } from '../definitions'
export type { TSource, TSourceType, IStatusResponse, ISoundSystemChangeProps } from '../definitions'

export type { IPlayParams } from '@common/BaseSoundSystem/definitions'

export type { IObjectPosition, TObjectPositions } from '@common/canvas/definitions'
