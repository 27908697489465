// Új ikont úgy tudsz felvenni, hogy adsz neki egy nevet, amit mi kódban használunk és
// ahhoz értéknek a grafikus által megadott fájlnevet rakod, leszedve az "icon_" prefixet.
// !!! FONTOS !!!
// Az itt újonnan felvett ikont a projects/ugyesedni/appShell/game.scss fájlban is fel kell venni!
// Valamint kell spritesheetet buildelni:
// node projects/ugyesedni/apps/systemSpriteSheetMaker.js
export enum EIcon {
  Adjustment   = 'adjustment',
  Back         = 'arrow_back',
  Down         = 'arrow_down',
  Forward      = 'arrow_forward',
  Up           = 'arrow_up',
  Diagram      = 'diagram',
  Bulb         = 'bulb',
  CheckMark    = 'check_mark',
  Hamburger    = 'hamburger_menu',
  Home         = 'home',
  Heart        = 'heart',
  Information  = 'information',
  En           = 'lang_en',
  Hu           = 'lang_hu',
  Rs           = 'lang_rs',
  De           = 'lang_de',
  Pl           = 'lang_pl',
  Es           = 'lang_es',
  Si           = 'lang_si',
  Ua           = 'lang_ua',
  Ro           = 'lang_ro',
  Sk           = 'lang_sk',
  Language     = 'language',
  Methodology  = 'methodology',
  Play         = 'play',
  Question     = 'question_mark',
  Refresh      = 'refresh',
  Settings     = 'settings',
  SoundOff     = 'sound_off',
  SoundOn      = 'sound_on',
  MusicOff     = 'music_off',
  MusicOn      = 'music_on',
  NarrationOff = 'narration_off',
  NarrationOn  = 'narration_on',
  Special      = 'special_settings',
  XMark        = 'x_mark', // eslint-disable-line @typescript-eslint/naming-convention
  PadLock      = 'padlock',
  Minus        = 'minus',
  Plus         = 'plus',
  Pause        = 'pause',
  Cart         = 'cart',
  Record       = 'record',
  Stop         = 'stop',
  Spaceship    = 'spaceship'
}
