import BaseInput from '@common/elements/Input/Input'
import type { IProps } from '@common/elements/Input/Input'

import styles from './Input.css'

/**
 * A játéktér saját input komponensének factory-ja.
 * Az ős input wrappert használja.
 * @param props - JSX props {@link IProps}.
 */
export default function Input (props: IProps): HTMLInputElement {
  return <BaseInput className={ styles.input } { ...props } />
}
